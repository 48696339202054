import * as Yup from 'yup';

const numberReg = /^\d+$/;
const numberRegMessage = 'Can only contain integers';

const requiredMessage = 'Required';

export default Yup.object().shape({
  initiator: Yup.object().shape({
    first_name: Yup.string().required(requiredMessage),
    last_name: Yup.string().required(requiredMessage)
  }),
  phone: Yup.string()
    .min(10, 'Must contain 10 numbers')
    .matches(numberReg, 'Must contain 10 numbers')
    .nullable()
    .required(requiredMessage),
  officeZip: Yup.string()
    .min(5, 'Invalid zip')
    .matches(numberReg, numberRegMessage)
    .required(requiredMessage),
  recaptchaToken: Yup.string(),
  verifyBySms: Yup.bool().required()
});

export const SmsSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, 'Codes are six digits')
    .matches(numberReg, numberRegMessage)
    .required(requiredMessage),
  uuid: Yup.string().required(requiredMessage),
  initiator: Yup.object().shape({
    first_name: Yup.string().required(requiredMessage),
    last_name: Yup.string().required(requiredMessage)
  }),
  phone: Yup.string()
    .min(10, 'Must contain 10 numbers')
    .matches(numberReg, 'Must contain 10 numbers')
    .nullable()
    .required(requiredMessage),
  officeZip: Yup.string()
    .min(5, 'Invalid zip')
    .matches(numberReg, numberRegMessage)
    .required(requiredMessage),
  recaptchaToken: Yup.string()
});

export const StandaloneSchema = Yup.object().shape({
  initiator: Yup.object().shape({
    first_name: Yup.string().required(requiredMessage),
    last_name: Yup.string().required(requiredMessage),
    email: Yup.string()
      .email('Invalid email')
      .required(requiredMessage)
  }),
  patientLastName: Yup.string().required(requiredMessage),
  patientDOB: Yup.date()
    .max(new Date(), 'Date cannot be a future value')
    .required(requiredMessage),
  phone: Yup.string()
    .min(10, 'Must contain 10 numbers')
    .matches(numberReg, 'Must contain 10 numbers')
    .nullable()
    .required(requiredMessage),
  officeZip: Yup.string()
    .min(5, 'Invalid zip')
    .matches(numberReg, numberRegMessage)
    .required(requiredMessage),
  recaptchaToken: Yup.string(),
  verifyBySms: Yup.bool().required()
});

export const StandaloneSmsSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, 'Codes are six digits')
    .matches(numberReg, numberRegMessage)
    .required(requiredMessage),
  initiator: Yup.object().shape({
    first_name: Yup.string().required(requiredMessage),
    last_name: Yup.string().required(requiredMessage),
    email: Yup.string()
      .email('Invalid email')
      .required(requiredMessage)
  }),
  patientLastName: Yup.string().required(requiredMessage),
  patientDOB: Yup.date()
    .max(new Date(), 'Date cannot be a future value')
    .required(requiredMessage),
  phone: Yup.string()
    .min(10, 'Must contain 10 numbers')
    .matches(numberReg, 'Must contain 10 numbers')
    .nullable()
    .required(requiredMessage),
  officeZip: Yup.string()
    .min(5, 'Invalid zip')
    .matches(numberReg, numberRegMessage)
    .required(requiredMessage),
  recaptchaToken: Yup.string()
});

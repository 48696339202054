import React from 'react';
import Container from 'react-bootstrap/Container';
import queryString from 'query-string';

export default function(props) {
  const query = queryString.parse(props?.location?.search);

  return (
    <Container className="content--app">
      <h1>
        You have successfully registered
        {query && query?.practice && query?.npi
          ? ` to practice NPI ${query.npi}, ${query.practice}`
          : null}
        !
      </h1>
      <p>
        Check your email to verify your account and login. The email address that you registered
        with is your login username.
      </p>
    </Container>
  );
}

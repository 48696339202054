import React, { PureComponent } from 'react';
import { errorValues } from './utils';
import { format } from 'libphonenumber-js';
import { Button, Modal } from 'react-bootstrap';
import VirtualizedSelect from 'react-virtualized-select';

import CurrentOffice from './current-office';

const physicianOption = ({ focusedOption, option, selectValue, style }) => {
  const { main_telephone, name, office_name, address1, city, state, zip } = option;

  const handleClick = () => selectValue(option);

  const classNames =
    option.selectKey === focusedOption.selectKey
      ? 'option--custom option--custom__focused'
      : 'option--custom';

  let cityStateZip = '';
  if (city && state) cityStateZip += `${city}, ${state}`;
  if (!city && state) cityStateZip += `${state}`;
  if (state && zip) cityStateZip += ` ${zip}`;

  return (
    <div className={classNames} key={option.id} style={style} onClick={handleClick}>
      <div>
        <strong>{name}</strong>
      </div>

      {address1 && <div>{address1}</div>}
      <div>{cityStateZip}</div>
      {main_telephone && (
        <div>
          <em>{format(main_telephone, 'US', 'National')}</em>
        </div>
      )}
    </div>
  );
};

export default class OfficeField extends PureComponent {
  state = {
    showModal: false
  };

  closeDialog = () => this.setState({ showModal: false });

  render() {
    const {
      field,
      form: { setFieldValue, setFieldTouched },
      options,
      defaultValue,
      label,
      loading,
      onChange,
      onRemove,
      placeholder,
      clearable,
      rxOrder,
      disabled
    } = this.props;
    const { fieldTouched, fieldError, errorClass } = errorValues(this.props);

    const loadingSpinner = loading ? (
      <i className="fa fa-fw fa-lg fa-circle-notch fa-spin" />
    ) : null;
    const renderedLabel = label ? (
      <label>
        {label}
        {loadingSpinner}
      </label>
    ) : null;

    return (
      <div className={errorClass}>
        {renderedLabel}{' '}
        {onRemove && !disabled ? (
          <a onClick={() => onRemove()}>
            <i className="fa fa-minus-square fa-fw fa-lg" />
          </a>
        ) : null}
        {field.value ? (
          <CurrentOffice
            onChange={val => {
              if (val !== field.value) {
                setFieldValue(field.name, val);
                if (!val) setFieldValue('physician', '');
              }
            }}
            office={field.value}
          />
        ) : (
          <VirtualizedSelect
            {...field}
            name={field.name}
            clearable={!!clearable}
            disabled={disabled}
            options={options}
            value={field.value ? field.value : defaultValue}
            valueKey="name"
            onChange={val => {
              if (val !== field.value) {
                setFieldValue(field.name, val);
                if (onChange) onChange(val);
              }
            }}
            optionRenderer={physicianOption}
            optionHeight={85}
            placeholder={placeholder ? placeholder : 'Select...'}
            onBlur={() => setFieldTouched(field.name, true, true)}
          />
        )}
        {fieldTouched && fieldError && <span className="has-error">{fieldError}</span>}
      </div>
    );
  }
}

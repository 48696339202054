// Not sure where we will land with domains, subdomains, or just path driven
const base = '/exogen-rx/app';
import { push } from 'connected-react-router';
export const buildUrl = path => (path ? `${base}${path}` : base);

export const pushUrl = path => push(buildUrl(path));

export const pushRawUrl = url => push(url);

export const urlStitch = (url, keyValues) => {
  let newUrl = url;
  Object.keys(keyValues).forEach(item => {
    newUrl = newUrl.replace(`:${item}`, keyValues[item]);
  });

  return newUrl;
};

export const URLS = {
  SSO: buildUrl('/sso'),
  LOGIN: buildUrl('/login'),
  CMN: buildUrl('/order/:orderId/:orderLocation/documents/cmn'),
  ORDER: buildUrl('/order/:orderId/:orderLocation'),
  REGISTRATION: buildUrl('/registration'),
  REGISTRATION_SUCCESS: buildUrl('/registration/success'),
  QUICK_SIGN_STANDALONE: buildUrl('/quick-sign-standalone/:docType'),
  QUICK_SIGN: buildUrl('/quick-sign/:uuid'),
  QUICK_SIGN_LOGGED_IN: buildUrl('/my-quick-sign/:uuid'),
  BASE: buildUrl()
};

export const URL_WHITELIST = [
  /\/sso/,
  /\/login/,
  /\/sso/,
  /\/registration/,
  /\/registration\/success/,
  /\/quick-sign/
];

export const urlRequiresLogin = url => !URL_WHITELIST.some(reg => url.match(reg));

import React, { useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OfficeField from 'shared/components/form/fields/office';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  office: Yup.object()
    .shape({})
    .nullable()
    .required()
});

export default function OfficeModal({ contactAdmin, error, handleHide, loading, offices, show }) {
  const initialValues = offices && offices.length === 1 ? { office: offices[0] } : { office: '' };
  const isInitialValid = validationSchema.isValidSync(initialValues);

  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>Practice Found!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          displayName={'ContactAdminForm'}
          onSubmit={values => {
            contactAdmin(values.office);
          }}
          isInitialValid={isInitialValid}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          render={({ submitForm, isValid }) => (
            <form>
              <h3>Join a Previously Registered Practice</h3>
              <p>
                We found MyBV360 practices associated with your provider or practice. Would you like
                to contact practice administrators?
              </p>
              <Row className="form-group">
                <Col md={8}>
                  <Field
                    name="office"
                    component={OfficeField}
                    placeholder="Please select..."
                    options={offices}
                    defaultValue={''}
                    disabled={loading}
                    label="Request Access"
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <Col md={9} className="has-error">
                  {error && (
                    <>
                      <i className="fa fa-exclamation-triangle fa-fw has-error" />
                      {error}
                    </>
                  )}
                </Col>
                <Col md={3}>
                  <div className="float-end">
                    <Button
                      className="btn btn-primary btn--fw"
                      disabled={!isValid || loading}
                      onClick={submitForm}
                    >
                      Request
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
}

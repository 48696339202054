import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const _get = require('lodash.get');

const errorKeyMap = {
  initiator: {
    first_name: 'Initiator First Name',
    last_name: 'Initiator Last Name',
    title: 'Initiator Title',
    email: 'Initiator Email'
  },
  patientLastName: 'Patient Last Name',
  patientDOB: 'Patient Date of Birth',
  phone: 'Initiator Phone',
  patient: {
    first_name: 'Patient First Name',
    last_name: 'Patient Last Name',
    home_address: 'Patient Home Address',
    city: 'Patient City',
    gender: 'Patient Gender',
    date_of_birth: 'Patient Date of Birth',
    state: 'Patient State',
    zip: 'Patient Zip',
    primary_telephone: 'Patient Phone',
    height: 'Patient Height',
    weight: 'Patient Weight'
  },
  physician_if_other_first_name: 'Physician First Name',
  physician_if_other_last_name: 'Physician Last Name',
  physician_if_other_npi: 'Physician NPI',
  physician: {
    first_name: 'Physician First Name',
    last_name: 'Physician Last Name',
    npi_number: 'Physician NPI'
  },
  primary_insurance_policy_num: 'Medicare ID/Policy ID',
  lengthOfNeed: 'Estimated Length of Need',
  diagnosis: {
    icd10_0: 'Diagnosis Code'
  },
  radioGraphicEvidence: 'Radiographic Evidence Question',
  openSurgicalIntervention: 'Open Surgical Intervention Question',
  uuid: 'Quick-sign UUID',
  officeZip: 'Office Zip'
};

const isString = val => typeof val === 'string' || val instanceof String;

class ErrorModal extends Component {
  errorItem = (key, error, idx) => {
    const errorLabel = _get(errorKeyMap, key);
    let label = errorLabel || key;

    // the label cannot be an object or react will bork
    if (typeof label === 'object' && label !== null) label = key;

    return (
      <li key={idx}>
        <a onClick={() => this.scrollAndExit(`order--${key}`)}>
          <i className={`fa fa-fw fa-exclamation-triangle has-error`} />
          {label}:
        </a>{' '}
        {error}
      </li>
    );
  };

  formatErrors = (errors, result, segment = null) => {
    const errorKeys = Object.keys(errors);

    errorKeys.forEach(key => {
      if (isString(errors[key]) || Array.isArray(errors[key])) {
        const value = Array.isArray(errors[key]) ? errors[key].join(' ') : errors[key];
        const errorKey = segment ? `${segment}${key}` : key;

        result.push(this.errorItem(errorKey, value, result.length, segment));
      } else {
        this.formatErrors(errors[key], result, `${key}.`);
      }
    });

    return result;
  };

  render() {
    const { errors, close, show } = this.props;
    const errorSection = this.formatErrors(errors, []);

    return (
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className={`fa fa-fw fa-exclamation-triangle has-error`} /> CMN Errors
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please fix the following errors:</p>
          <div className="help-block">
            <div className="container--validation">{errors ? <ul>{errorSection}</ul> : null}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-default btn--fw" onClick={close}>
            <span>
              <i className="fa fa-times fa-fw" />
              Close
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ErrorModal.propTypes = {
  close: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default ErrorModal;

import React from 'react';
import QuickSignCmn from 'bv360/components/exogen-rx/cmn/quick-sign-standalone';

export default function(props) {
  switch (props.quickSignDocType) {
    case 'cms_cmn':
    default:
      return <QuickSignCmn {...props} />;
  }
}

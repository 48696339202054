import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import { NavLink, Link } from 'react-router-dom';
import { URLS } from 'exogen-rx/utils/urls';
import { upperCaseFirstLetter } from 'shared/utils/string';
import { logout } from 'bv360/redux/actions/user';
import { newOrder } from 'bv360/redux/actions/orders';
const LeftLogo = require('static/images/exogen-logo-white.png');
const RightLogo = require('static/images/bioventus-right.png');

const exLogoStyle = { maxHeight: '70px', padding: '13px 0' };
const bvLogoStyle = { maxHeight: '70px' };

export default function({
  demoable = false,
  loadingDemo = false,
  isDemo = false,
  externalPage = false,
  startDemo,
  stopDemo
}) {
  const user = useSelector(state => state.user && state.user.user);
  const dispatch = useDispatch();

  const username = user
    ? `${upperCaseFirstLetter(user.firstName)} ${upperCaseFirstLetter(user.lastName)}`
    : null;

  const handleNewOrder = evt => {
    evt.preventDefault();
    dispatch(newOrder());
  };

  return (
    <header id="TopHead" style={{ backgroundImage: 'url(/static/images/dot.png)' }}>
      <div className="clear" />

      <div className="Logo">
        {externalPage ? (
          <img style={exLogoStyle} src={LeftLogo} alt="bv360" />
        ) : (
          <Link to={URLS.BASE}>
            <img style={exLogoStyle} src={LeftLogo} alt="bv360" />
          </Link>
        )}
      </div>

      <div className="LogoRight">
        <a href="https://www.bioventusglobal.com/" target="_blank">
          <img src={RightLogo} alt="Bio Ventus Global" />
        </a>
      </div>

      {!externalPage && (
        <div>
          <nav className="navbar navbar-expand-lg mainmenu">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="fa fa-bars" />
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto" id="TopNav">
                <li className="dropdown">
                  <Link to={URLS.BASE}>
                    <i className="fa fa-home" /> Dashboard
                    <span />
                  </Link>
                </li>
                <li className="dropdown">
                  <a
                    href="#"
                    onClick={handleNewOrder}
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-user-md" /> Order<span></span>
                  </a>
                </li>

                <li className="dropdown">
                  <a href="/mybv360/app" role="button" aria-haspopup="true" aria-expanded="false">
                    MyBV360<span></span>
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav float-lg-right" id="TopNavRight">
                <li className="dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {username} <i className="fa fa-angle-down sign-out-icon" />
                    <span></span>
                  </a>
                  <ul className="dropdown-menu">
                    {demoable && (
                      <li onClick={isDemo ? stopDemo : startDemo}>
                        <a href="#" onClick={evt => evt.preventDefault()}>
                          {loadingDemo ? (
                            <i className="fa fa-fw fa-circle-notch fa-spin" />
                          ) : (
                            <i className="fa fa-user-circle" />
                          )}{' '}
                          {isDemo ? 'Stop' : 'Start'} Demo
                        </a>
                      </li>
                    )}
                    <li onClick={() => dispatch(logout())}>
                      <a href="#" onClick={evt => evt.preventDefault()}>
                        <i className="fa fa-sign-out-alt" /> Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}

      <div className="clear" />
    </header>
  );
}

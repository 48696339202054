import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';

import * as FormDataActions from 'bv360/redux/actions/form-data';
import { LOADING } from 'bv360/redux/reducers/orders';

import ErrorBoundary from 'exogen/components/error/error';

import Panel from 'exogen/components/common/pages/panel';
import Header from 'bv360/components/exogen-rx/app/header';
import Footer from 'bv360/components/app/footer';
import FormSelector from './form-selector-standalone';

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import VerifyCodeForm from './verify-code-form';
import VerifyDetailsForm from './verify-details-form-standalone';

const StandAloneQuickSign = props => {
  const [today] = useState(new Date());
  const [qsValues, setQsValues] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FormDataActions.getICD10s());
  }, []);

  const {
    currentOrder,
    quickSignDocType,
    initiatorEmail,
    smsVerified,
    smsSent,
    error,
    loading,
    smsPending,
    icd10s,
    user
  } = useSelector(state => ({
    currentOrder: state.orders.currentOrder,
    quickSignDocType: state.orders.quickSignDocType,
    initiatorEmail: state.orders.initiatorEmail,
    smsVerified: state.orders.smsVerified,
    smsSent: state.orders.smsSent,
    error: state.orders.error,
    loading: state.orders.loading.includes(LOADING.SMS_CODE),
    smsPending: state.orders.loading.includes(LOADING.SMS_CODE),
    icd10s: state.icd10s,
    user: state.user.user
  }));

  const { googleReCaptchaProps, match } = props;
  const combinedProps = {
    ...props,
    currentOrder,
    quickSignDocType,
    initiatorEmail,
    smsVerified,
    smsSent,
    error,
    loading,
    smsPending,
    icd10s,
    user
  };

  const verificationInProcess = !smsVerified || !currentOrder || !quickSignDocType;
  const showActualForm = smsVerified && currentOrder && quickSignDocType;

  return (
    <>
      <Header externalPage={true} />
      <ErrorBoundary>
        <Container className="content--app">
          {verificationInProcess && (
            // <Panel title="Sign in or Verify by Phone">
            <Panel title="Verify Identity">
              {smsSent ? (
                <VerifyCodeForm
                  doc_type={match.params.docType}
                  error={error}
                  initiatorEmail={initiatorEmail}
                  loading={loading}
                  qsValues={qsValues}
                  setQsValues={setQsValues}
                  standalone
                />
              ) : (
                <VerifyDetailsForm
                  error={error}
                  executeRecaptcha={googleReCaptchaProps.executeRecaptcha}
                  setQsValues={setQsValues}
                  smsPending={smsPending}
                  today={today}
                  doc_type={match.params.docType}
                />
              )}
            </Panel>
          )}

          {showActualForm && (
            <Panel title="Quick Sign">
              <FormSelector {...combinedProps} qsValues={qsValues} />
            </Panel>
          )}
        </Container>
      </ErrorBoundary>
      <Footer />
    </>
  );
};

export default withGoogleReCaptcha(StandAloneQuickSign);

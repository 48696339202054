import createReducer from 'shared/utils/createReducer';

import {
  DOCUSIGN_EMBEDDED,
  DOCUSIGN_EMBEDDED_SUCCESS,
  DOCUSIGN_EMBEDDED_FAIL
} from 'bv360/redux/actions/docusign';

const initialState = {
  error: null,
  sending: false
};

export default createReducer(initialState, {
  [DOCUSIGN_EMBEDDED]: state => {
    return { ...state, sending: true };
  },
  [DOCUSIGN_EMBEDDED_SUCCESS]: state => {
    return { ...state, sending: false };
  },
  [DOCUSIGN_EMBEDDED_FAIL]: (state, action) => {
    return { ...state, sending: false, error: action.error };
  }
});

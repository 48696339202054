import apiClient from 'exogen-rx/utils/api-client';
import getApiError from 'shared/utils/error';
import { pushUrl, URLS, pushRawUrl } from 'exogen-rx/utils/urls';
import { showToast } from 'shared/utils/toast';
import _pickBy from 'lodash.pickby';
import { SubmitTypes } from 'shared/utils/constants';
import { generateUniqueId } from 'shared/utils/uuid';

import { normalizeOrderDates, formatDate } from 'exogen/redux/actions/orders';

export const DRAFT_DELETE = 'DRAFT_DELETE';

export const DRAFTS_ALL = 'DRAFTS_ALL';
export const DRAFTS_ALL_SUCCESS = 'DRAFTS_ALL_SUCCESS';
export const DRAFTS_ALL_FAIL = 'DRAFTS_ALL_FAIL';

export const DRAFT_SUBMIT_SUCCESS = 'DRAFT_SUBMIT_SUCCESS';

export const NEW_ORDER = 'NEW_ORDER';

export const ORDER_CANCEL_MODAL_TOGGLE = 'ORDER_CANCEL_MODAL_TOGGLE';

export const ORDER_SUBMISSION_TOGGLE_MODAL = 'ORDER_SUBMISSION_TOGGLE_MODAL';

export const ADD_PHYSICIAN_TOGGLE_MODAL = 'ADD_PHYSICIAN_TOGGLE_MODAL';

export const ORDERS_ALL = 'ORDERS_ALL';
export const ORDERS_ALL_SUCCESS = 'ORDERS_ALL_SUCCESS';
export const ORDERS_ALL_FAIL = 'ORDERS_ALL_FAIL';

export const ORDER_GET = 'ORDER_GET';
export const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS';
export const ORDER_GET_FAIL = 'ORDER_GET_FAIL';

export const ORDER_CLEAR_CURRENT = 'ORDER_CLEAR_CURRENT';

export const ORDER_SAVE_CHANGES = 'ORDER_SAVE_CHANGES';

export const ORDER_SUBMIT = 'ORDER_SUBMIT';
export const ORDER_SUBMIT_SUCCESS = 'ORDER_SUBMIT_SUCCESS';
export const ORDER_SUBMIT_FAIL = 'ORDER_SUBMIT_FAIL';

export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';

export const SET_SUBMIT_TYPE = 'SET_SUBMIT_TYPE';

export const SEND_SMS_CODE = 'SEND_SMS_CODE';
export const SEND_SMS_CODE_SUCCESS = 'SEND_SMS_CODE_SUCCESS';
export const SEND_SMS_CODE_FAIL = 'SEND_SMS_CODE_FAIL';

export const VERIFY_QUICK_SIGN = 'VERIFY_QUICK_SIGN';
export const VERIFY_QUICK_SIGN_SUCCESS = 'VERIFY_QUICK_SIGN_SUCCESS';
export const VERIFY_QUICK_SIGN_FAIL = 'VERIFY_QUICK_SIGN_FAIL';

export const GET_QUICK_SIGN = 'GET_QUICK_SIGN';
export const GET_QUICK_SIGN_SUCCESS = 'GET_QUICK_SIGN_SUCCESS';
export const GET_QUICK_SIGN_FAIL = 'GET_QUICK_SIGN_FAIL';

export function getQuickSign(uuid) {
  return async dispatch => {
    try {
      dispatch({ type: GET_QUICK_SIGN });
      const result = await apiClient.get(`exogen-rx/api/documents/quick-sign/${uuid}`);

      dispatch({ type: GET_QUICK_SIGN_SUCCESS, result: result.body });
    } catch (response) {
      if (response.status === 404) {
        alert('Quick sign not found. Returning to dashboard...');
        dispatch(pushRawUrl(URLS.BASE));
      }
      const error =
        response.body && response.body.error ? response.body.error : 'Error retrieving quick sign';

      dispatch({ type: GET_QUICK_SIGN_FAIL, error });
    }
  };
}

export function sendSmsCode(formValues, standalone = false) {
  return {
    types: [SEND_SMS_CODE, SEND_SMS_CODE_SUCCESS, SEND_SMS_CODE_FAIL],
    promise: client =>
      client.post(`exogen-rx/api/documents/quick-sign${standalone ? '-standalone' : ''}/sms`, {
        params: { ...formValues, patientDOB: formatDate(formValues.patientDOB, 'YYYY-MM-DD') }
      })
  };
}

export function verifySmsQuickSign(formValues, standalone = false) {
  return {
    types: [VERIFY_QUICK_SIGN, VERIFY_QUICK_SIGN_SUCCESS, VERIFY_QUICK_SIGN_FAIL],
    sms: true,
    promise: client =>
      client.post(
        `exogen-rx/api/documents/quick-sign${standalone ? '-standalone' : ''}/sms-verify`,
        { params: { ...formValues, patientDOB: formatDate(formValues.patientDOB, 'YYYY-MM-DD') } }
      )
  };
}

export function verifyQuickSign(formValues) {
  const vals = { ...formValues, patientDOB: formatDate(formValues.patientDOB, 'YYYY-MM-DD') };
  return {
    types: [VERIFY_QUICK_SIGN, VERIFY_QUICK_SIGN_SUCCESS, VERIFY_QUICK_SIGN_FAIL],
    promise: client => client.post('exogen-rx/api/documents/quick-sign', { params: vals })
  };
}

export function getDrafts() {
  return {
    types: [DRAFTS_ALL, DRAFTS_ALL_SUCCESS, DRAFTS_ALL_FAIL],
    promise: client => client.get('exogen-rx/api/drafts')
  };
}

export function getOrders() {
  return {
    types: [ORDERS_ALL, ORDERS_ALL_SUCCESS, ORDERS_ALL_FAIL],
    promise: client => client.get('exogen-rx/api/orders')
  };
}

export function getDashboardData() {
  return dispatch => {
    dispatch(getOrders());
    dispatch(getDrafts());
  };
}

export function setCurrentOrder(currentOrderId, orderLocation) {
  const orderId = (currentOrderId + '').startsWith('tmp')
    ? currentOrderId
    : parseInt(currentOrderId, 10);

  return (dispatch, getState) => {
    dispatch({
      type: SET_CURRENT_ORDER,
      currentOrderId: orderId,
      orderLocation
    });
    const isDraft = orderLocation === 'drafts';
    let currentOrder = null;

    if (isDraft) {
      const ordersState = getState().orders;
      const unsavedDraft = ordersState.drafts.find(
        draft => draft.client_id === ordersState.currentOrderId && !draft.hasOwnProperty('id')
      );

      if (unsavedDraft) {
        return dispatch({
          type: ORDER_GET,
          currentOrder: { ...unsavedDraft, isDraft: true }
        });
      }
    }

    dispatch({
      types: [ORDER_GET, ORDER_GET_SUCCESS, ORDER_GET_FAIL],
      isDraft: (currentOrderId + '').startsWith('tmp'),
      orderId,
      orderLocation,
      currentOrder,
      promise: client => client.get(`exogen-rx/api/orders/${orderId}`)
    });
  };
}

export function clearCurrentOrder() {
  return {
    type: ORDER_CLEAR_CURRENT
  };
}

function createNewOrder(id) {
  return {
    type: NEW_ORDER,
    id
  };
}

export function newOrder() {
  return (dispatch, getState) => {
    const id = generateUniqueId();
    dispatch(createNewOrder(id));
    dispatch(pushUrl(`/order/${id}/drafts`));
  };
}

export function openOrder(orderId, orderLocation, scroll = null) {
  return dispatch => {
    const url = scroll
      ? `/order/${orderId}/${orderLocation}?scroll=${scroll}`
      : `/order/${orderId}/${orderLocation}`;
    dispatch(pushUrl(url));
  };
}

export function setSubmitType(submitType = SubmitTypes.submit) {
  return {
    type: SET_SUBMIT_TYPE,
    submitType
  };
}

export function toggleCancelOrderModal(show) {
  return {
    type: ORDER_CANCEL_MODAL_TOGGLE,
    show
  };
}

export function toggleSubmissionModal() {
  return {
    type: ORDER_SUBMISSION_TOGGLE_MODAL
  };
}

export function toggleAddPhysicianModal() {
  return {
    type: ADD_PHYSICIAN_TOGGLE_MODAL
  };
}

export function deleteIfClean(id) {
  return {
    type: DRAFT_DELETE,
    id,
    currentIfClean: true
  };
}

export function deleteDraft(id) {
  return async (dispatch, getState) => {
    const drafts = getState().orders.drafts;
    const draft = drafts.find(dr => dr.client_id === id);

    if (draft.id) {
      try {
        const result = await apiClient.delete(`exogen-rx/api/drafts/${draft.id}`);
        dispatch({ type: DRAFT_DELETE, id });
      } catch (body) {
        const message =
          body && body.error
            ? `Error deleting draft on server: ${body.error}`
            : 'Error deleting draft on server';
        showToast({ type: 'error', message });
      }
    } else {
      dispatch({ type: DRAFT_DELETE, id });
    }
  };
}

const saveDraft = async (formValues, dispatch, getState) => {
  let ordersState = getState().orders;

  if (!ordersState.currentOrderId || !formValues.id) {
    alert("Can't save, returning to dashboard.");
    dispatch(pushUrl('/'));
    return;
  }

  dispatch({
    type: ORDER_SAVE_CHANGES,
    formValues
  });

  showToast({ type: 'info', message: 'Order saved...' });

  ordersState = getState().orders;

  const updatedDraft = ordersState.currentOrder;

  if (!updatedDraft) {
    alert("Can't find draft, returning to dashboard.");
    dispatch(pushUrl('/'));
    return;
  }

  if (!String(updatedDraft.order.id).startsWith('tmp')) {
    alert('Invalid draft id.  Returning to dashboard.');
    dispatch(pushUrl('/'));
    return;
  }

  if (updatedDraft.order.id !== formValues.id || updatedDraft.client_id !== formValues.id) {
    alert('Invalid draft form.  Returning to dashboard');
    dispatch(pushUrl('/'));
    return;
  }

  if (updatedDraft.order.sap_id || formValues.sap_id) {
    alert('Invalid draft form.  Returning to dashboard');
    dispatch(pushUrl('/'));
    return;
  }

  const promise =
    updatedDraft && updatedDraft.hasOwnProperty('id')
      ? apiClient.put(`exogen-rx/api/drafts/${updatedDraft.id}`, { params: updatedDraft })
      : apiClient.post('exogen-rx/api/drafts', { params: updatedDraft });

  try {
    const response = await promise;
    dispatch({
      type: DRAFT_SUBMIT_SUCCESS,
      draft: response.body.draft
    });
    return true;
  } catch ({ status, body = {}, header }) {
    if (status === 404) {
      showToast({
        type: 'error',
        message: 'Draft was not found.  Deleting and redirecting to dashboard.'
      });
      dispatch({ type: DRAFT_DELETE, id: updatedDraft.client_id });
      dispatch(push('/'));
    }
    if (
      status === 401 &&
      header.hasOwnProperty('user-change') &&
      header['user-change'] === 'true'
    ) {
      window.location.replace('/exogen-direct/app');
    } else {
      const err = body && body.error ? body.error : null;
      const message = err ? `Error saving draft to server: ${err}` : 'Error saving draft to server';
      showToast({ type: 'error', message });
    }
    return false;
  }
};

export function saveChanges(formValues) {
  return async (dispatch, getState) => {
    await saveDraft(formValues, dispatch, getState);
  };
}

const disableForm = () => {
  return { type: ORDER_SUBMIT };
};

const submitSuccess = (result, orderId) => {
  return {
    type: ORDER_SUBMIT_SUCCESS,
    result,
    orderId
  };
};

const submitFail = (error = {}, result) => {
  return {
    type: ORDER_SUBMIT_FAIL,
    error,
    result
  };
};

export const submitOrder = draft => {
  return async (dispatch, getState) => {
    const state = getState().orders;

    if (!draft || !draft.id) {
      alert('Invalid order');
      return null;
    }

    if (!String(draft.id).startsWith('tmp')) {
      alert('Order Previously Submitted');
      return null;
    }

    if (!draft.id) {
      alert('Invalid order');
      return null;
    }

    // dispatch(saveChanges(draft));
    const success = await saveDraft(draft, dispatch, getState);

    if (!success) return;

    const outerDraft = state.currentOrder;

    if (!outerDraft.order) {
      alert('Invalid order');
      return null;
    }

    let submittedOrder = {
      ...draft,
      client_id: draft.id,
      id: null,
      documents: outerDraft.order.documents
    };

    submittedOrder = normalizeOrderDates({
      ..._pickBy(submittedOrder, x => x !== '' && x !== null),
      diagnosis: _pickBy(submittedOrder.diagnosis, x => x !== '' && x !== null),
      patient: _pickBy(submittedOrder.patient, x => x !== '' && x !== null)
    });

    dispatch(disableForm());

    try {
      if (state.submitType === SubmitTypes.submit) {
        const { body } = await apiClient.post('exogen-rx/api/orders', { params: submittedOrder });
        dispatch(submitSuccess(body, draft.id));
        dispatch(pushUrl('/'));
        showToast({ type: 'success', message: 'Your order has been submitted.' });
      } else {
        const { body } = await apiClient.post('exogen-rx/api/orders/test', {
          params: submittedOrder
        });
        dispatch({ type: ORDER_TEST_SUCCESS });
        // showToast({ type: 'success', message: 'Order is valid' });
      }
    } catch ({ status, body = { error: 'Bad Request' }, header, ...rest }) {
      if (
        status === 401 &&
        header.hasOwnProperty('user-change') &&
        header['user-change'] === 'true'
      ) {
        window.location.replace('/exogen-rx/app');
      } else {
        const err = body && body.error ? body.error : 'Error submitting order';
        dispatch(submitFail(err, body));
      }
    }
  };
};

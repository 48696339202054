import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import _set from 'lodash.set';
import { Field } from 'formik';
import AddressLookupField from 'shared/components/form/fields/address-lookup';
import RadioGroupField from 'shared/components/form/fields/radio-group';
import TextField from 'shared/components/form/fields/text';
import PhoneField from 'shared/components/form/fields/phone';
import SelectField from 'shared/components/form/fields/select';
import NpiField from 'shared/components/form/fields/npi';
import EmailVerifyField from 'shared/components/form/fields/email-verify';
import { States } from 'shared/utils/constants';
import apiClient from 'exogen-rx/utils/api-client';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Redirect } from 'react-router-dom';
import { URLS } from 'bv360/utils/urls';
import OfficeModal from './office-modal';
import ContactAdminModal from './contact-admin-modal';
import ModifyPracticeModal from './modify-practice-modal';

const stateOptions = States.map(state => ({ value: state.id, label: state.name }));

export const transformRadioValue = value => value === 'true';

export const radioOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

function onKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

class RegistrationForm extends React.PureComponent {
  state = {
    loading: false,
    error: null,
    redirectToContactSuccess: false,
    showOfficeModal: false,
    showContactAdminModal: false,
    showPracticeModal: false,
    newPractice: null,
    offices: [],
    officesWithUsers: []
  };

  constructor(props) {
    super(props);
  }

  updateOrIgnore = (field, value) => {
    if (!value) return;
    this.props.setFieldValue(field, value, true);
    const touched = _set(this.props.touched, field, true);
    this.props.setTouched(touched);
  };

  handleOfficeResult = (offices, officesWithUsers) => {
    if ((offices && offices.length > 0) || (officesWithUsers && officesWithUsers?.length > 0)) {
      this.setState({
        offices,
        officesWithUsers,
        showOfficeModal: true
      });
    } else {
      this.setState({
        offices: [],
        officesWithUsers: [],
        showOfficeModal: false
      });
      this.props.setFieldValue('newOffice', true);
    }

    const { provider, newOffice } = this.props.values;
    const { physicianExists } = provider;

    if (physicianExists && !newOffice) {
      this.props.setFieldValue('practice.office_id', '');
      this.props.setFieldValue('practice.npi', '');
      this.props.setFieldValue('practice.name', '');
      this.props.setFieldValue('practice.phone', '');
      this.props.setFieldValue('practice.fax', '');
      this.props.setFieldValue('practice.street', '');
      this.props.setFieldValue('practice.suite', '');
      this.props.setFieldValue('practice.state', '');
      this.props.setFieldValue('practice.city', '');
      this.props.setFieldValue('practice.zip', '');
      this.props.setFieldValue('practice.fax', '');
    }
  };

  handleContactAdmins = async office => {
    this.setState({ loading: true, error: null });
    const { values } = this.props;

    const { firstName, middleName = '', lastName, email } = values?.practiceContact;

    try {
      const response = await apiClient.post('exogen-rx/api/practices/join', {
        params: { practice: office?.practice?.id, firstName, lastName, email }
      });

      if (response?.body?.error) {
        this.setState({ error: response.body.error });
      } else {
        this.updateOrIgnore('practice.name', office?.practice?.name);
        this.updateOrIgnore('practice.npi', office?.practice?.npi);
        this.setState({ loading: false });

        this.setState({ redirectToContactSuccess: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: e?.body?.error || 'Error requesting access' });
    }
  };

  updatePractice = ({ name, street, suite, state, city, zip, fax, phone }) => {
    this.updateOrIgnore('practice.office_id', '');
    this.updateOrIgnore('practice.name', name);
    this.updateOrIgnore('practice.phone', phone);
    this.updateOrIgnore('practice.fax', fax);
    this.updateOrIgnore('practice.street', street);
    this.updateOrIgnore('practice.suite', suite);
    this.updateOrIgnore('practice.state', state);
    this.updateOrIgnore('practice.city', city);
    this.updateOrIgnore('practice.zip', zip);
    this.setState({ showPracticeModal: false, newPractice: null });
  };

  practiceNpiUpdate = practice => {
    const { newOffice } = this.props.values;
    const { practiceExists, officesWithUsers } = practice;
    if (newOffice) {
      this.updatePractice(practice);
      this.setState({ newPractice: null });
    } else if (practice.name) {
      this.setState({ newPractice: { name: practice.name }, showPracticeModal: true });
    }

    if (practiceExists && officesWithUsers && officesWithUsers?.length > 0) {
      this.setState({
        officesWithUsers,
        showContactAdminModal: true
      });
    }
  };

  providerNpiUpdate = ({
    firstName,
    middleName,
    lastName,
    fax,
    phone,
    physicianExists,
    specialty,
    offices,
    officesWithUsers
  }) => {
    this.handleOfficeResult(offices, officesWithUsers);
    this.props.setFieldValue('provider.physicianExists', physicianExists);
    const updateFunc =
      !firstName && !lastName && !specialty ? this.props.setFieldValue : this.updateOrIgnore;

    const { sameAsContact } = this.props.values.provider;

    if (!sameAsContact) {
      updateFunc('provider.firstName', firstName);
      updateFunc('provider.middleName', middleName);
      updateFunc('provider.lastName', lastName);
    }

    updateFunc('provider.specialty', specialty);
  };

  handleSameAsContact = isSame => {
    const { values } = this.props;
    if (!values.practiceContact) return null;
    const { physicianExists } = values;

    const { firstName, middleName = '', lastName, email } = values.practiceContact;
    const npi = values && values.npi ? values.npi : '';

    if (isSame && !physicianExists) {
      this.updateOrIgnore('provider.firstName', firstName);
      this.updateOrIgnore('provider.middleName', middleName);
      this.updateOrIgnore('provider.lastName', lastName);
      this.updateOrIgnore('provider.email', email);
      this.updateOrIgnore('provider.npi', npi);
    }
  };

  handleExistingOffice = ({
    id,
    name,
    main_telephone,
    fax,
    address1,
    address2,
    city,
    state,
    zip
  }) => {
    this.updateOrIgnore('practice.office_id', id);
    this.updateOrIgnore('practice.name', name);
    this.updateOrIgnore('practice.phone', main_telephone);
    this.updateOrIgnore('practice.fax', fax);
    this.updateOrIgnore('practice.street', address1);
    this.updateOrIgnore('practice.suite', address2);
    this.updateOrIgnore('practice.state', state);
    this.updateOrIgnore('practice.city', city);
    this.updateOrIgnore('practice.zip', zip);
    this.setState({
      showOfficeModal: false,
      offices: [],
      officesWithUsers: []
    });
    this.props.setFieldValue('newOffice', false);
  };

  handleNewOffice = () => {
    this.props.setFieldValue('newOffice', true);
    this.setState({
      showOfficeModal: false,
      offices: [],
      officesWithUsers: []
    });
  };

  updateRecaptchaAndSubmit = async evt => {
    const recaptchaToken = await this.props.googleReCaptchaProps.executeRecaptcha('register_user');
    this.props.setFieldValue('recaptchaToken', recaptchaToken, true);
  };

  hasPopulatedValidPracticeContact = () => {
    const { values, errors: formErrors } = this.props;

    if (
      !values.practiceContact ||
      !values?.practiceContact?.firstName ||
      !values?.practiceContact?.lastName ||
      !values?.practiceContact?.email
    ) {
      return false;
    } else if (formErrors.practiceContact) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { error, isValid, loading, values } = this.props;

    const { practice, newOffice } = values;
    const { physicianExists, sameAsContact, inviteProvider, physicianIsUser } = values.provider;

    const {
      showOfficeModal,
      showContactAdminModal,
      showPracticeModal,
      newPractice,
      offices,
      officesWithUsers,
      redirectToContactSuccess
    } = this.state;

    if (redirectToContactSuccess)
      return (
        <Redirect
          to={`${URLS.ADMIN_CONTACTED}?practice=${values?.practice?.name}&npi=${values?.practice
            ?.npi || 'name not known'}`}
        />
      );

    return (
      <form
        onSubmit={async evt => {
          evt.preventDefault();
          await this.updateRecaptchaAndSubmit();
          this.props.handleSubmit(evt);
        }}
        onKeyDown={onKeyDown}
        autoComplete="off"
        className="registration-form"
      >
        <Card>
          <Card.Header>Practice Contact</Card.Header>
          <Card.Body>
            <Row>
              <Col>
                Please input your contact information. You will be enrolled as the first practice
                user. Other users can be added later. (* are required)
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="practiceContact.firstName"
                  label="First Name*"
                  maxLength={64}
                  component={TextField}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="practiceContact.lastName"
                  label="Last Name*"
                  maxLength={64}
                  component={TextField}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="practiceContact.email"
                  label="Email* (Your Email Will be Your Username)"
                  maxLength={64}
                  component={EmailVerifyField}
                  verifyUrl="/mybv360/api/verify-user/contact/"
                  placeholder="Email*"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>Provider</Card.Header>
          <Card.Body>
            <Row>
              <Col>
                Please add one provider from the practice for identification purposes. Other
                providers can be added later. (* are required)
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="provider.sameAsContact"
                  label="Is the contact above also the provider?*"
                  component={RadioGroupField}
                  elems={radioOptions}
                  onChange={this.handleSameAsContact}
                  disabled={!this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>
            {!sameAsContact && (
              <Row>
                <Col>
                  <Field
                    name="provider.inviteProvider"
                    label="Invite the physician to MyBV360?*"
                    component={RadioGroupField}
                    elems={radioOptions}
                    disabled={physicianIsUser || !this.hasPopulatedValidPracticeContact()}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="provider.npi"
                  label="Provider NPI*"
                  populateCallback={this.providerNpiUpdate}
                  enumerationType="NPI-1"
                  placeholder="NPI Number"
                  component={NpiField}
                  disabled={!this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="provider.firstName"
                  label="First Name*"
                  maxLength={64}
                  component={TextField}
                  disabled={physicianExists || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="provider.middleName"
                  label="Middle Name"
                  maxLength={64}
                  component={TextField}
                  disabled={physicianExists || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="provider.lastName"
                  label="Last Name*"
                  maxLength={64}
                  component={TextField}
                  disabled={physicianExists || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  label="DEA Number"
                  placeholder="AB1234567"
                  name="provider.dea"
                  component={TextField}
                  disabled={physicianExists || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>

            <Row>
              {inviteProvider && !sameAsContact && (
                <Col xs={12} sm={6}>
                  <Field
                    label="Email*"
                    name="provider.email"
                    component={TextField}
                    disabled={physicianExists || !this.hasPopulatedValidPracticeContact()}
                  />
                </Col>
              )}

              <Col xs={12} sm={6}>
                <Field
                  label="Specialty"
                  name="provider.specialty"
                  component={TextField}
                  disabled={physicianExists || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>Practice</Card.Header>
          <Card.Body>
            <Row>
              <Col>Please input the name and location of your practice. (* are required)</Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.npi"
                  label="Practice NPI*"
                  populateCallback={this.practiceNpiUpdate}
                  enumerationType="NPI-2"
                  placeholder="NPI Number"
                  component={NpiField}
                  disabled={!this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.name"
                  label="Practice Name*"
                  placeholder="Practice Name"
                  maxLength={64}
                  component={TextField}
                  disabled={!newOffice || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field name="practice.taxId" maxLength={9} label="Tax ID" component={TextField} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.phone"
                  label="Phone Number*"
                  placeholder="(555) 555-5555"
                  showValidation
                  component={PhoneField}
                  disabled={!this.hasPopulatedValidPracticeContact()}
                  // disabled={!newOffice}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.phone_extension"
                  label="Phone Extension"
                  placeholder="12345"
                  showValidation
                  component={TextField}
                  maxLength={9}
                  disabled={!this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.fax"
                  label="Fax Number*"
                  placeholder="(555) 555-5555"
                  showValidation
                  component={PhoneField}
                  disabled={!this.hasPopulatedValidPracticeContact()}
                  // disabled={!newOffice}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.street"
                  aptField="practice.suite"
                  cityField="practice.city"
                  stateField="practice.state"
                  zipField="practice.zip"
                  showValidation
                  maxLength={64}
                  label="Street*"
                  component={AddressLookupField}
                  disabled={!newOffice || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.suite"
                  maxLength={64}
                  label="Suite"
                  component={TextField}
                  disabled={!newOffice || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.city"
                  maxLength={32}
                  label="City*"
                  component={TextField}
                  disabled={!newOffice || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.state"
                  label="State*"
                  showValidation
                  simpleValue
                  component={SelectField}
                  options={stateOptions}
                  disabled={!newOffice || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="practice.zip"
                  maxLength={5}
                  label="Zip*"
                  component={TextField}
                  disabled={!newOffice || !this.hasPopulatedValidPracticeContact()}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row>
          <Col>
            <Alert variant="danger" show={!!error}>
              <i className="fa fa-fw fa-exclamation-triangle" /> {error}
            </Alert>
          </Col>
        </Row>
        <div style={{ paddingLeft: '30px' }}>
          If assistance is needed, please call myBV360 Customer Care at{' '}
          <a href={'tel: 800-836-4080'}>800-836-4080</a>.
        </div>

        <Row>
          <Col></Col>
          <Col md={{ span: 2, offset: 10 }}>
            <Button type="submit" disabled={!isValid || loading}>
              {loading ? <i className="fa fa-fw fa-circle-notch fa-spin" /> : 'Register'}
            </Button>
          </Col>
        </Row>
        {showOfficeModal && (
          <OfficeModal
            error={this.state.error}
            loading={loading}
            offices={offices}
            officesWithUsers={officesWithUsers}
            contactAdmin={this.handleContactAdmins}
            show={showOfficeModal}
            useExistingOffice={this.handleExistingOffice}
            createNewOffice={this.handleNewOffice}
          />
        )}
        {showContactAdminModal && (
          <ContactAdminModal
            handleHide={() => this.setState({ showContactAdminModal: false })}
            loading={loading}
            offices={officesWithUsers}
            contactAdmin={this.handleContactAdmins}
            show={showContactAdminModal}
            error={this.state.error}
          />
        )}
        {this.props.showContactAdminModal && (
          <ContactAdminModal
            handleHide={() => this.props.handleRegistrationClose()}
            loading={loading}
            offices={this.props.officesWithUsers}
            contactAdmin={this.handleContactAdmins}
            show={this.props.showContactAdminModal}
            error={this.state.error}
          />
        )}
        {showPracticeModal && (
          <ModifyPracticeModal
            newPractice={newPractice}
            currentPractice={practice}
            close={() => this.updatePractice(practice)}
            updatePractice={() => this.updatePractice(newPractice)}
            show={showPracticeModal}
          />
        )}
      </form>
    );
  }
}

export default withGoogleReCaptcha(RegistrationForm);

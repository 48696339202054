import { REHYDRATE } from 'redux-persist/lib/constants';
import { emptyOrder } from 'shared/utils/orders';
import createReducer from 'shared/utils/createReducer';
import cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import * as OrderActions from 'bv360/redux/actions/orders';
import { DOCUMENT_UPLOAD_SUCCESS } from 'bv360/redux/reducers/documents';
import { USER_NEW } from 'bv360/redux/reducers/user';
import getApiError from 'shared/utils/error';

const initialState = {
  currentOrderId: null,
  currentOrder: null,
  initiatorEmail: null,
  payer: null,
  orderLocation: null,
  orderSubmitting: false,
  showAddPhysicianModal: false,
  showSubmissionModal: false,
  showValidationModal: false,
  error: null,
  sending: false,
  drafts: [],
  orders: [],
  orderCancel: { loading: false, error: null },
  loading: [],
  quickSignDocType: null,
  quickSignVerified: false,
  smsSent: false,
  smsVerified: false
};

const addLoadingItem = (arr, key) => [...arr, key];
const removeLoadingItem = (arr, key) => [...arr].filter(el => el !== key);

export const LOADING = {
  SMS_CODE: 'sms-code',
  SMS_VERIFY: 'sms-verifuy'
};

export default createReducer(initialState, {
  [REHYDRATE]: (state, action) => {
    return action.payload && action.payload.orders
      ? {
          ...state,
          ...action.payload.orders,
          initiatorEmail: null,
          showAddPhysicianModal: false,
          showSubmissionModal: false,
          showValidationModal: false,
          currentOrderId: null,
          currentOrder: null,
          orderLocation: null,
          submitType: 'Submit',
          orderSubmitting: false,
          uploadError: null,
          loading: [],
          quickSignDocType: null,
          quickSignVerified: false,
          smsSent: false,
          smsVerified: false,
          error: null
        }
      : {
          ...state
        };
  },
  [DOCUMENT_UPLOAD_SUCCESS]: (state, action) => {
    const { document } = action;
    let currentOrder = state.currentOrder;

    currentOrder = {
      ...currentOrder,
      documents: [document, ...currentOrder.documents]
    };

    return {
      ...state,
      currentOrder
    };
  },
  [OrderActions.DRAFT_DELETE]: (state, action) => {
    const filteredDrafts = action.currentIfClean
      ? state.drafts.filter(draft => draft.client_id !== action.id || draft.order.__dirty)
      : state.drafts.filter(draft => draft.client_id !== action.id);
    return {
      ...state,
      drafts: [...filteredDrafts]
    };
  },
  [OrderActions.DRAFT_SUBMIT_SUCCESS]: (state, action) => {
    const draftIndex = state.drafts.findIndex(draft => draft.client_id === action.draft.client_id);

    return {
      ...state,
      drafts: [
        ...state.drafts.slice(0, draftIndex),
        action.draft,
        ...state.drafts.slice(draftIndex + 1)
      ],
      currentOrder: action.draft
    };
  },
  [OrderActions.DRAFTS_ALL]: (state, action) => {
    return { ...state, loading: addLoadingItem(state.loading, 'drafts') };
  },
  [OrderActions.DRAFTS_ALL_SUCCESS]: (state, { result }) => {
    return { ...state, drafts: result.data, loading: removeLoadingItem(state.loading, 'drafts') };
  },
  [OrderActions.DRAFTS_ALL_FAIL]: (state, action) => {
    return { ...state, loading: removeLoadingItem(state.loading, 'drafts') };
  },
  [OrderActions.NEW_ORDER]: (state, action) => {
    const emptyOrd = emptyOrder();
    // Can't decide which is better for the temp id, for now we will be redundant
    emptyOrd.order.id = action.id;
    emptyOrd.client_id = action.id;

    return {
      ...state,
      drafts: [...state.drafts, emptyOrd],
      currentOrder: emptyOrd,
      currentOrderId: action.id,
      orderLocation: 'drafts'
    };
  },
  [OrderActions.ORDER_CANCEL_MODAL_TOGGLE]: (state, action) => {
    return {
      ...state,
      orderCancel: {
        ...state.orderCancel,
        showModal: action.show,
        error: null
      }
    };
  },
  [OrderActions.ORDER_CLEAR_CURRENT]: state => {
    return {
      ...state,
      currentOrder: null
    };
  },
  [OrderActions.ORDER_SAVE_CHANGES]: (state, action) => {
    if (action.formValues.id !== state.currentOrderId) {
      alert('Potential bad save.  Ignoring changes.');
      return {
        submissionErrors: null,
        ...state
      };
    }

    const formChanges = cloneDeep(action.formValues);

    const currentOrder = {
      ...state.currentOrder,
      order: {
        ...state.currentOrder.order,
        ...formChanges
      },
      __dirty: true
    };

    return {
      ...state,
      submissionErrors: null,
      currentOrder
    };
  },
  [OrderActions.ORDER_SUBMISSION_TOGGLE_MODAL]: state => {
    return {
      ...state,
      showSubmissionModal: !state.showSubmissionModal
    };
  },
  [OrderActions.ADD_PHYSICIAN_TOGGLE_MODAL]: state => {
    return {
      ...state,
      showAddPhysicianModal: !state.showAddPhysicianModal
    };
  },
  [OrderActions.ORDER_SUBMIT]: state => {
    return {
      ...state,
      orderSubmitting: true,
      submissionErrors: null
    };
  },
  [OrderActions.ORDER_SUBMIT_SUCCESS]: (state, action) => {
    if (action.result.orders) {
      return {
        ...state,
        // orders: action.result.orders,
        drafts: state.drafts.filter(draft => draft.id !== action.orderId),
        unlinkedDocuments: { ...state.unlinkedDocuments, items: action.result.documents },
        orderSubmitting: false,
        showSubmissionModal: false,
        submissionError: null
      };
    }

    return {
      ...state,
      orderSubmitting: false
    };
  },
  [OrderActions.ORDER_SUBMIT_FAIL]: (state, action) => {
    // captureForSentry(action, 'Error submitting order');
    let errors = {};

    if (action.result.is_valid_order === false && action.result.order_errors) {
      errors = { ...errors, ...action.result.order_errors };
    }

    if (action.result.error) {
      errors = { ...errors, generic: action.result.error };
    }

    if (action.error && Object.keys(errors).length === 0) {
      errors = { ...errors, generic: action.error };
    }

    return {
      ...state,
      orderSubmitting: false,
      submissionErrors: errors,
      showSubmissionModal: true
    };
  },
  [OrderActions.ORDERS_ALL]: (state, action) => {
    return { ...state, loading: addLoadingItem(state.loading, 'orders') };
  },
  [OrderActions.ORDERS_ALL_SUCCESS]: (state, { result }) => {
    return { ...state, orders: result.data, loading: removeLoadingItem(state.loading, 'orders') };
  },
  [OrderActions.ORDERS_ALL_FAIL]: (state, action) => {
    return { ...state, loading: removeLoadingItem(state.loading, 'orders') };
  },
  [OrderActions.ORDER_GET]: (state, action) => {
    return {
      ...state,
      currentOrder: action.currentOrder
    };
  },
  [OrderActions.ORDER_GET_SUCCESS]: (state, action) => {
    return {
      ...state,
      currentOrder: { ...action.result.data, isDraft: action.isDraft }
    };
  },
  [OrderActions.SET_CURRENT_ORDER]: (state, action) => {
    return {
      ...state,
      currentOrderId: action.currentOrderId,
      orderLocation: action.orderLocation,
      orderSubmitting: false
    };
  },
  [OrderActions.GET_QUICK_SIGN]: state => {
    return {
      ...state,
      quickSignDocType: null,
      quickSignVerified: false,
      loading: addLoadingItem(state.loading, LOADING.SMS_CODE)
    };
  },
  [OrderActions.GET_QUICK_SIGN_SUCCESS]: (state, { result }) => {
    return {
      ...state,
      quickSignVerified: true,
      quickSignDocType: result.data.docType,
      currentOrder: result.data.order,
      currentOrderId: result.data.order.id,
      loading: removeLoadingItem(state.loading, LOADING.SMS_CODE)
    };
  },
  [OrderActions.GET_QUICK_SIGN_FAIL]: (state, action) => {
    return {
      ...state,
      loading: removeLoadingItem(state.loading, LOADING.SMS_CODE),
      error: action.error
    };
  },
  [OrderActions.SEND_SMS_CODE]: state => {
    return {
      ...state,
      error: null,
      loading: addLoadingItem(state.loading, LOADING.SMS_CODE),
      smsVerified: false,
      smsSent: false,
      initiatorEmail: null,
      payer: null
    };
  },
  [OrderActions.SEND_SMS_CODE_SUCCESS]: (state, { result }) => {
    return {
      ...state,
      loading: removeLoadingItem(state.loading, LOADING.SMS_CODE),
      smsSent: true,
      initiatorEmail: result.data.email,
      payer: result.data.payer
    };
  },
  [OrderActions.SEND_SMS_CODE_FAIL]: (state, action) => {
    return {
      ...state,
      loading: removeLoadingItem(state.loading, LOADING.SMS_CODE),
      error: action.error.body.error,
      initiatorEmail: null,
      payer: null
    };
  },
  [OrderActions.VERIFY_QUICK_SIGN]: state => {
    return {
      ...state,
      quickSignDocType: null,
      quickSignVerified: false,
      loading: addLoadingItem(state.loading, LOADING.SMS_CODE)
    };
  },
  [OrderActions.VERIFY_QUICK_SIGN_SUCCESS]: (state, { result, sms }) => {
    return {
      ...state,
      quickSignVerified: true,
      quickSignDocType: result.data.docType,
      currentOrder: result.data.order,
      currentOrderId: result.data.order.id,
      smsVerified: sms,
      loading: removeLoadingItem(state.loading, LOADING.SMS_CODE)
    };
  },
  [OrderActions.VERIFY_QUICK_SIGN_FAIL]: (state, action) => {
    console.log(action);
    return {
      ...state,
      loading: removeLoadingItem(state.loading, LOADING.SMS_CODE),
      error: getApiError(action.error)
    };
  },
  [USER_NEW]: state => {
    return {
      ...state,
      orders: [],
      drafts: []
    };
  }
});

import * as Yup from 'yup';
import npiValidate, { npiReg, npiRegMessage } from 'shared/utils/npi';

const numberReg = /^\d+$/;
const numberRegMessage = 'Can only contain integers';

const oneToNinetyNine = /^0*(?:[1-9][0-9]?)$/;
const oneToNinetyNineMessage = 'Enter a number between 1 and 99';

const requiredMessage = 'Required';

export default Yup.object().shape({
  initiator: Yup.object().shape({
    first_name: Yup.string().required(requiredMessage),
    last_name: Yup.string().required(requiredMessage),
    title: Yup.string().required(requiredMessage)
  }),
  patient: Yup.object().shape({
    first_name: Yup.string().required(requiredMessage),
    last_name: Yup.string().required(requiredMessage),
    home_address: Yup.string().required(requiredMessage),
    city: Yup.string().required(requiredMessage),
    gender: Yup.string().required(requiredMessage),
    date_of_birth: Yup.string().required(requiredMessage),
    state: Yup.string().required(requiredMessage),
    zip: Yup.string()
      .matches(numberReg, numberRegMessage)
      .required(requiredMessage),
    primary_telephone: Yup.string()
      .min(10, 'Must contain 10 numbers')
      .matches(numberReg, 'Must contain 10 numbers')
      .required(requiredMessage),
    height: Yup.number()
      .nullable(true)
      .positive()
      .integer('Only integers are accepted')
      .typeError('Only integers are accepted')
      .min(10, 'Height must be a min of 10 inches')
      .max(99, 'Height must be a max of 99 inches')
      .transform((value, originalValue) => (originalValue.trim() === '' ? null : value)),
    weight: Yup.number()
      .nullable(true)
      .positive()
      .integer('Only integers are accepted')
      .typeError('Only integers are accepted')
      .min(10, 'Weight must be a min of 10 pounds')
      .max(999, 'Weight must be a max of 99 pounds')
      .transform((value, originalValue) => (originalValue.trim() === '' ? null : value))
  }),
  physician_if_other_first_name: Yup.string().required(requiredMessage),
  physician_if_other_last_name: Yup.string().required(requiredMessage),
  physician_if_other_npi: Yup.string()
    .min(10, 'Must contain 10 numbers')
    .test('npi-test', 'NPI is invalid', npiValidate)
    .matches(npiReg, npiRegMessage)
    .required(requiredMessage),
  primary_insurance_policy_num: Yup.string().required(requiredMessage),
  // .min(11, 'Must contain 11 characters'),
  lengthOfNeed: Yup.string()
    .required(requiredMessage)
    .matches(oneToNinetyNine, oneToNinetyNineMessage),
  diagnosis: Yup.object().shape({
    icd10_0: Yup.string().required(requiredMessage)
  }),
  radioGraphicEvidence: Yup.string().required(requiredMessage),
  openSurgicalIntervention: Yup.string().required(requiredMessage),
  // officeZip: Yup.string()
  //   .matches(numberReg, numberRegMessage)
  //   .required(requiredMessage),
  // patientDOB: Yup.string().required(requiredMessage),
  // patientLastName: Yup.string().required(requiredMessage),
  // userEmail: Yup.string().required(requiredMessage),
  uuid: Yup.string().required(requiredMessage),
  officeZip: Yup.string().required(requiredMessage)
});

export const DOCUSIGN_EMBEDDED = 'DOCUSIGN_EMBEDDED';
export const DOCUSIGN_EMBEDDED_SUCCESS = 'DOCUSIGN_EMBEDDED_SUCCESS';
export const DOCUSIGN_EMBEDDED_FAIL = 'DOCUSIGN_EMBEDDED_FAIL';

export const DOCUSIGN_WAITING_FOR_ME = 'DOCUSIGN_WAITING_FOR_ME';
export const DOCUSIGN_WAITING_FOR_ME_SUCCESS = 'DOCUSIGN_WAITING_FOR_ME_SUCCESS';
export const DOCUSIGN_WAITING_FOR_ME_FAIL = 'DOCUSIGN_WAITING_FOR_ME_FAIL';

export function embeddedSigning(envelopeId) {
  return async dispatch => {
    dispatch({ type: DOCUSIGN_EMBEDDED, envelopeId });
    try {
      const response = await apiClient.get(
        `/exogen-rx/api/docusign/document/${envelopeId}/embedded`
      );
      const { body, error } = response;
      if (error) return dispatch({ type: DOCUSIGN_EMBEDDED_FAIL, error: getApiError(error) });
      dispatch({ type: DOCUSIGN_EMBEDDED_SUCCESS });
      window.location.replace(body.url);
    } catch (e) {
      dispatch({ type: DOCUSIGN_EMBEDDED_FAIL, error: getApiError(e) });
    }
  };
}

export function getDocsWaitingForMe() {
  return {
    types: [DOCUSIGN_WAITING_FOR_ME, DOCUSIGN_WAITING_FOR_ME_SUCCESS, DOCUSIGN_WAITING_FOR_ME_FAIL],
    promise: client => client.get('exogen/api/docusign/document/waiting-for-me')
  };
}

import apiClient from 'exogen/utils/api-client';
import superagent from 'superagent';
import * as Sentry from '@sentry/browser';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { generateUniqueId } from 'shared/utils/uuid';

export const DOCUMENTS_ALL = 'DOCUMENTS_ALL';
export const DOCUMENTS_ALL_SUCCESS = 'DOCUMENTS_ALL_SUCCESS';
export const DOCUMENTS_ALL_FAIL = 'DOCUMENTS_ALL_FAIL';
export const DOCUMENT_DELETE = 'DOCUMENT_DELETE';
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS';
export const DOCUMENT_DELETE_FAIL = 'DOCUMENT_DELETE_FAIL';
export const DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD';
export const DOCUMENT_UPLOAD_FAIL = 'DOCUMENT_UPLOAD_FAIL';
export const DOCUMENT_UPLOAD_PROGRESS = 'DOCUMENT_UPLOAD_PROGRESS';
export const DOCUMENT_UPLOAD_SUCCESS = 'DOCUMENT_UPLOAD_SUCCESS';
export const DOCUMENT_UPLOAD_SUCCESS_REMOVE_UPLOAD = 'DOCUMENT_UPLOAD_SUCCESS_REMOVE_UPLOAD';
export const DOCUMENT_REMOVE = 'DOCUMENT_REMOVE';
export const UPLOADS_OPEN_MODAL = 'UPLOADS_OPEN_MODAL';

const timeout = {
  response: 90000, // Wait 5 seconds for the server to start sending,
  deadline: 90000 // but allow 1 minute for the file to finish loading.
};

const STATUS = {
  PENDING: 'pending',
  AVAILABLE: 'available',
  UPLOADED: 'uploaded'
};

const { PENDING } = STATUS;

const initialState = {
  deletingInProgress: false,
  documentDeleteError: false,
  docusignLoading: false,
  docusignError: false,
  advocacyModalShow: false,
  uploads: [],
  uploadModalShow: false,
  uploadError: null
};

const captureForSentry = (action, message) => {
  if (__SENTRY__) {
    Sentry.configureScope(scope => {
      scope.setExtra('action', action);
    });
    Sentry.captureMessage(message);
  }
};

export default function reducer(state = initialState, action = {}) {
  let docIndex;

  switch (action.type) {
    case REHYDRATE:
      return action.payload && action.payload.documents
        ? {
            ...state,
            ...action.payload.documents,
            deletingInProgress: false,
            documentDeleteError: false,
            docusignLoading: false,
            docusignError: false,
            uploadModalShow: false,
            uploadError: null,
            uploads: []
          }
        : {
            ...state
          };
    case DOCUMENT_UPLOAD:
      return {
        ...state,
        uploadModalShow: false,
        uploads: [...state.uploads, action.document],
        uploadError: null
      };
    case DOCUMENT_UPLOAD_PROGRESS:
      docIndex = state.uploads.findIndex(doc => doc.id === action.document.id);
      return {
        ...state,
        uploads: [
          ...state.uploads.slice(0, docIndex),
          { ...action.document },
          ...state.uploads.slice(docIndex + 1)
        ]
      };
    case DOCUMENT_UPLOAD_SUCCESS_REMOVE_UPLOAD:
      docIndex = state.uploads.findIndex(doc => doc.id === action.oldId);

      const newUploads = [
        ...state.uploads.slice(0, docIndex),
        ...state.uploads.slice(docIndex + 1)
      ];

      return {
        ...state,
        uploads: newUploads
      };
    case DOCUMENT_UPLOAD_FAIL:
      docIndex = state.uploads.findIndex(doc => doc.id === action.document.id);
      return {
        ...state,
        uploads: [...state.uploads.slice(0, docIndex), ...state.uploads.slice(docIndex + 1)],
        uploadError: action.error && action.error.message ? action.error.message : action.error
      };
    case UPLOADS_OPEN_MODAL:
      return {
        ...state,
        uploadModalShow: action.show
      };
    default:
      return state;
  }
}

export function deleteDocument(id) {
  return async dispatch => {
    dispatch({ type: DOCUMENT_DELETE, id });
    try {
      const result = await apiClient.delete(`documents/${id}/`);

      dispatch({ type: DOCUMENT_DELETE_SUCCESS, id });
    } catch (error) {
      dispatch({ type: DOCUMENT_DELETE_FAIL });
    } finally {
      dispatch(getUnlinkedDocuments());
    }
  };
}

export function removeDocument(id) {
  return {
    type: DOCUMENT_REMOVE,
    id
  };
}

function removeUpload(id) {
  return {
    type: DOCUMENT_UPLOAD_SUCCESS_REMOVE_UPLOAD,
    oldId: id
  };
}

export function toggleUploadModal(show) {
  return {
    type: UPLOADS_OPEN_MODAL,
    show
  };
}

function uploadProgress(document) {
  return {
    type: DOCUMENT_UPLOAD_PROGRESS,
    document
  };
}

function uploadDocInit(document) {
  return {
    type: DOCUMENT_UPLOAD,
    document
  };
}

function uploadSuccess({ document, orderId, draftId, orderLocation }) {
  return {
    type: DOCUMENT_UPLOAD_SUCCESS,
    document,
    orderId,
    draftId,
    orderLocation
  };
}

function uploadFail(error, document) {
  return {
    type: DOCUMENT_UPLOAD_FAIL,
    error,
    document
  };
}

export function uploadDocument({
  draftId,
  description,
  file,
  original_filename,
  document_types,
  orderId,
  orderLocation,
  successCallback
}) {
  const document = {
    id: generateUniqueId(),
    file,
    description,
    original_filename
  };

  const data = new FormData();
  data.append('file', file);
  data.append('original_filename', original_filename);
  data.append('document_types', JSON.stringify(document_types));
  data.append('description', description);
  data.append('order_id', orderId);
  data.append('draft_id', draftId);
  data.append('status', PENDING);

  return dispatch => {
    dispatch(uploadDocInit(document));

    superagent
      .post('/exogen-rx/api/documents/')
      .set('BIOVENTUS-USER-ID', localStorage.getItem('user_id'))
      .set('BIOVENTUS-CLIENT-ID', 'exogen-direct')
      .timeout(timeout)
      .send(data)
      .on('progress', e => {
        const percent = typeof e.percent === 'undefined' ? 100 : e.percent;
        dispatch(uploadProgress({ ...document, progress: percent }));
      })
      .end((err, result) => {
        if (!err && result && result.body && result.body.data) {
          dispatch(uploadSuccess({ document: result.body.data, orderId, draftId, orderLocation }));
          setTimeout(() => dispatch(removeUpload(document.id)), 2000);
          if (successCallback) successCallback();
        } else {
          captureForSentry(err, 'Document upload error');
          if (
            err &&
            err.status === 401 &&
            err.header &&
            err.header.hasOwnProperty('user-change') &&
            err.header['user-change'] === 'true'
          ) {
            window.location.replace('/mybv360/app');
          }
          dispatch(uploadFail(err, document));
        }
      });
  };
}

import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, toggleUserRegistrationModal } from 'bv360/redux/actions/user';
import 'react-json-pretty/themes/monikai.css';

import RegistrationForm from './form/form';

import './styles.css';
import validationSchema from './validationSchema';

const initialValues = {
  provider: {
    physicianExists: false,
    sameAsContact: false,
    inviteProvider: false
  },
  newOffice: true
};

const validate = values => {
  const errors = {};

  if (
    values.practice?.phone_extension &&
    // returns true if a non number is input
    isNaN(values.practice?.phone_extension)
  ) {
    errors.practice = { phone_extension: 'Must be a number' };
  }

  if (
    values.provider.email &&
    !values.provider.sameAsContact &&
    values.provider.inviteProvider === undefined
  ) {
    errors.provider = { inviteProvider: 'Required' };
  }

  if (!values.provider.sameAsContact && values.provider.inviteProvider && !values.provider.email) {
    errors.provider = { email: 'Required' };
  }

  if (
    !values.provider.sameAsContact &&
    values.provider.inviteProvider &&
    values.provider.email === values.practiceContact.email
  ) {
    errors.provider = { email: "Email cant' match the contact email" };
  }

  return errors;
};

export default function({ exogenRx = false, bv360 = false }) {
  const dispatch = useDispatch();
  const { loading, error, user, officesWithUsers, showContactAdminModal } = useSelector(
    state => state.user
  );

  const toggleRegModal = () => dispatch(toggleUserRegistrationModal());

  if (user && !user.is_superuser) return <h1>Access Denied</h1>;

  return (
    <Container className="content--app">
      <h1>Register</h1>
      <Row>
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            dispatch(registerUser(values, exogenRx, bv360));
          }}
          displayName={'RegistrationForm'}
          validationSchema={validationSchema}
          validate={validate}
          validateOnChange={true}
          render={props => (
            <RegistrationForm
              {...props}
              loading={loading}
              error={error}
              officesWithUsers={officesWithUsers}
              showContactAdminModal={showContactAdminModal}
              handleRegistrationClose={toggleRegModal}
            />
          )}
        />
      </Row>
    </Container>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

class WarnModal extends Component {
  render() {
    const { accept, close, show } = this.props;

    return (
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-exclamation-triangle fa-fw has-error" /> CMN Already Initiated in DocuSign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            A CMN for this order has already been initiated in DocuSign. Please check your email for the link. If you would like to continue, click Continue below and the initial link will be voided.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-default btn--fw" onClick={close}>
            <span>
              <i className="fa fa-times fa-fw" />
              Cancel
            </span>
          </button>
          <button type="button" className="btn btn-default btn--fw" onClick={accept}>
            <span>Continue</span>
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

WarnModal.propTypes = {
  close: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default WarnModal;

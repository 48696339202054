export const npiReg = /^[1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/;
export const npiRegMessage = "NPI's are 10 digits without leading 0's";

export default function validateNPI(npi) {
  if (!npi) return false;
  var tmp;
  var sum;
  var i;
  var j;
  i = npi.length;
  if (i == 15 && npi.indexOf('80840', 0, 5) == 0) sum = 0;
  else if (i == 10) sum = 24;
  else return false;
  j = 0;
  while (i != 0) {
    tmp = npi.charCodeAt(i - 1) - '0'.charCodeAt(0);
    if (j++ % 2 != 0) {
      if ((tmp <<= 1) > 9) {
        tmp -= 10;
        tmp++;
      }
    }
    sum += tmp;
    i--;
  }
  if (sum % 10 == 0) return true;
  else return false;
}

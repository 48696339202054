import React, { useState } from 'react';
import superagent from 'superagent';
import { errorValues, inputClassNames } from 'shared/components/form/fields/utils';
import ErrorMessage from './error-message';

async function verifyEmail(email, url) {
  if (!email) throw Error('Email is required');

  return superagent.get(`${url}${email}`);
}

export default props => {
  const { errorClass, fieldError } = errorValues(props);
  const {
    label,
    placeholder,
    disabled,
    field,
    maxLength = null,
    min = null,
    handleValid = null,
    showErrors = true,
    verifyUrl,
    form: { setFieldError, setFieldValue, setFieldTouched, setStatus }
  } = props;

  const verify = async evt => {
    setFieldError(field.name, undefined);
    setFieldTouched(field.name, true, true);
    setStatus({provider :{ email: undefined}})

    // if (!fieldError && field.value) {
    if (field.value) {
      const { body, status } = await verifyEmail(field.value, verifyUrl);      
      if (showErrors && status === 204) {
        setFieldError(field.name, 'User already exists');        
        setStatus({provider :{ email: 'User already exists'}})
      } 

      if (handleValid && status === 204) {
        handleValid(body);
      }
    }
  };

  const classes = inputClassNames(props, 'form-control');

  return (
    <div className={errorClass}>
      <label>{label}</label>
      <div>
        <input
          {...field}
          type="text"
          maxLength={maxLength}
          min={min}
          disabled={disabled}
          onChange={evt => setFieldValue(field.name, evt.target.value, true)}
          onBlur={verify}
          value={field.value || ''}
          placeholder={placeholder ? placeholder : label}
          className={classes}
        />
        <div>
          <ErrorMessage name={field.name} />
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import superagent from 'superagent';
import { errorValues, inputClassNames } from 'shared/components/form/fields/utils';
import ErrorMessage from './error-message';

async function getNpi(number, enumeration_type = '') {
  if (!number) throw Error('Number is required');

  return superagent.get('/mybv360/api/npi-lookup').query({
    number,
    enumeration_type
  });
}

const digitRegex = /^\d*$/;

export default props => {
  const [foundNpi, setFoundNpi] = useState(false);
  const { errorClass, fieldError } = errorValues(props);
  const {
    label,
    placeholder,
    disabled,
    field,
    populateCallback = null,
    form: { setFieldValue }
  } = props;

  const handleChange = async evt => {
    const { value } = evt.target;

    if (digitRegex.test(value)) {
      setFieldValue(field.name, value, true);

      if (value && value.length === 10) {
        try {
          const { body } = await getNpi(evt.target.value, props.enumerationType);
          // console.log(body);
          setFoundNpi(true);
          if (populateCallback) {
            populateCallback(body);
          }
        } catch (e) {
          setFoundNpi(false);
          if (populateCallback) {
            populateCallback({
              physicianExists: false,
              practiceExists: false,
              physicianIsUser: false
            });
          }
        }
      } else {
        setFoundNpi(false);
      }
    }
  };

  const classes = foundNpi ? 'form-control is-valid' : inputClassNames(props, 'form-control');

  return (
    <div className={errorClass}>
      <label>{label}</label>
      <div>
        <input
          {...field}
          type="text"
          maxLength={10}
          min={10}
          disabled={disabled}
          onChange={handleChange}
          value={field.value || ''}
          placeholder={placeholder ? placeholder : label}
          className={classes}
        />
        <div>
          <ErrorMessage name={field.name} />
        </div>
      </div>
    </div>
  );
};

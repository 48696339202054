import {PERSIST, REHYDRATE} from 'redux-persist/lib/constants';

const TOGGLE_FILTER = 'APP/TOGGLE_FILTER';
const ENABLE_ROUTING = 'APP/ENABLE_ROUTING';

const initialState = {
  rehydrated: false,
  routerStarted: false,
  filters: {
    drafts: false,
    orders: false
  },
};


export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PERSIST:
      return {
        ...state
      };
    case ENABLE_ROUTING:
      return {
        ...state,
        routerStarted: true
      };
    case REHYDRATE:
      return {
        ...state,
        rehydrated: true
      };
    default :
      return state;
  }
}


export function enableRouting() {
  return {
    type: ENABLE_ROUTING
  };
}

import React, { useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorBoundary from 'exogen/components/error/error';
import OfficeField from 'shared/components/form/fields/office';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  office: Yup.object()
    .shape({})
    .nullable()
    .required()
});

export default function OfficeModal({
  contactAdmin,
  error,
  loading,
  offices,
  officesWithUsers,
  createNewOffice,
  show,
  useExistingOffice
}) {
  const initialValues = offices && offices.length === 1 ? { office: offices[0] } : { office: '' };
  const isInitialValid = validationSchema.isValidSync(initialValues);
  const initialValuesContactAdmin =
    officesWithUsers && officesWithUsers.length === 1
      ? { office: officesWithUsers[0] }
      : { office: '' };

  const isInitialValidContactAdmin = validationSchema.isValidSync(initialValuesContactAdmin);

  return (
    <Modal show={show} onHide={createNewOffice}>
      <Modal.Header closeButton>
        <Modal.Title>Practice Found!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {officesWithUsers?.length > 0 && (
          <Formik
            displayName={'ContactAdminForm'}
            onSubmit={values => {
              contactAdmin(values.office);
            }}
            isInitialValid={isInitialValidContactAdmin}
            initialValues={initialValuesContactAdmin}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            render={({ submitForm, isValid, values }) => (
              <form>
                <h3>Join a Previously Registered Practice</h3>
                <p>
                  We found MyBV360 practices associated with your provider. Would you like to
                  contact practice administrators?
                </p>
                <Row className="form-group">
                  <Col md={8}>
                    <Field
                      name="office"
                      component={OfficeField}
                      placeholder="Please select..."
                      options={officesWithUsers}
                      defaultValue={''}
                      disabled={loading}
                      label="Request Access"
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col md={9}>
                    {error && (
                      <>
                        <i className="fa fa-exclamation-triangle fa-fw has-error" />
                        {error}
                      </>
                    )}
                  </Col>
                  <Col md={3}>
                    <div className="float-end">
                      <Button
                        className="btn btn-primary btn--fw"
                        disabled={!isValid || loading}
                        onClick={submitForm}
                      >
                        Request
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            )}
          />
        )}

        <ErrorBoundary>
          <Formik
            displayName={'RegisterPractice'}
            onSubmit={values => {
              useExistingOffice(values.office);
            }}
            isInitialValid={isInitialValid}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            render={({ submitForm, isValid, values }) => (
              <form>
                {officesWithUsers?.length > 0 && offices?.length > 0 && (
                  <Row className="form-group">
                    <Col sm={2}>
                      <hr />
                    </Col>
                    <Col sm={1}>Or</Col>
                    <Col sm={9}>
                      <hr />
                    </Col>
                  </Row>
                )}

                {offices?.length > 0 && (
                  <>
                    <h3>Register a New Practice</h3>
                    <p>
                      We found practices associated with your provider. Would you like to select an
                      existing practice or add a new practice?
                    </p>
                    <Row className="form-group">
                      <Col md={8}>
                        <Field
                          name="office"
                          component={OfficeField}
                          placeholder="Please select..."
                          options={offices}
                          defaultValue={''}
                          disabled={loading}
                          label="Select Existing Practice"
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <Col md={12}>
                        <div className="float-end">
                          {values.office && (
                            <Button
                              className="btn btn-primary btn--fw"
                              disabled={!isValid || loading}
                              onClick={submitForm}
                            >
                              Register
                            </Button>
                          )}{' '}
                        </div>
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <Col sm={2}>
                        <hr />
                      </Col>
                      <Col sm={1}>Or</Col>
                      <Col sm={9}>
                        <hr />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <Col md={12}>
                        None of the above practices are my practice. I want to create a new practice
                        not listed above.
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <Col md={12}>
                        <div className="float-end">
                          <Button className="btn btn-default btn--fw" onClick={createNewOffice}>
                            Create Practice
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </form>
            )}
          />
        </ErrorBoundary>
      </Modal.Body>
    </Modal>
  );
}

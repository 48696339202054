import * as Yup from 'yup';
import npiValidate, { npiReg, npiRegMessage } from 'shared/utils/npi';

const numberReg = /^\d+$/;
const numberRegMessage = 'Can only contain integers';

const nonNumberReg = /^\D*$/;
const nonNumberMessage = 'Cannot contain digits';

const deaReg = /^\S{2}\d{7}$/;
const deaMessage = 'DEA numbers must contain 2 letters, 6 digits, and 1 check digit';
const requiredMessage = 'Required';

export default Yup.object().shape({
  recaptchaToken: Yup.string(),
  newOffice: Yup.boolean().required(),
  practice: Yup.object().shape({
    name: Yup.string()
      .required(requiredMessage)
      .nullable(),
    npi: Yup.string()
      .min(10, 'Must contain 10 numbers')
      .test('npi-test', 'NPI is invalid', npiValidate)
      .matches(npiReg, npiRegMessage)
      .required(requiredMessage),
    taxId: Yup.string()
      .min(9, 'Must contain 9 numbers')
      .matches(numberReg, numberRegMessage),
    phone: Yup.string()
      .min(10, 'Must contain 10 numbers')
      .matches(numberReg, 'Must contain 10 numbers')
      .nullable()
      .required(requiredMessage),
    fax: Yup.string()
      .min(10, 'Must contain 10 numbers')
      .matches(numberReg, 'Must contain 10 numbers')
      .nullable()
      .required(requiredMessage),
    street: Yup.string().required(requiredMessage),
    suite: Yup.string().nullable(),
    city: Yup.string().required(requiredMessage),
    state: Yup.string().required(requiredMessage),
    zip: Yup.string()
      .matches(numberReg, numberRegMessage)
      .required(requiredMessage)
  }),
  practiceContact: Yup.object().shape({
    firstName: Yup.string()
      .matches(nonNumberReg, nonNumberMessage)
      .required(requiredMessage),
    lastName: Yup.string()
      .matches(nonNumberReg, nonNumberMessage)
      .required(requiredMessage),
    email: Yup.string()
      .email('Invalid email')
      .required(requiredMessage)
  }),
  provider: Yup.object().shape({
    sameAsContact: Yup.bool().required(requiredMessage),
    npi: Yup.string()
      .min(10, 'Must contain 10 numbers')
      .test('npi-test', 'NPI is invalid', npiValidate)
      .matches(npiReg, npiRegMessage)
      .required(requiredMessage),
    sln: Yup.string().matches(numberReg, numberRegMessage),
    dea: Yup.string().matches(deaReg, deaMessage),
    firstName: Yup.string()
      .matches(nonNumberReg, nonNumberMessage)
      .required(requiredMessage)
      .nullable(),
    lastName: Yup.string()
      .matches(nonNumberReg, nonNumberMessage)
      .required(requiredMessage)
      .nullable(),
    specialty: Yup.string().max(120),
    email: Yup.string().email('Invalid email')
    // inviteProvider: Yup.bool().when(['email', 'sameAsContact'], {
    //   is: (email, sameAsContact) => !sameAsContact && !!val,
    //   then: Yup.bool().required(requiredMessage)
    // })
  })
});

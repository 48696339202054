import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import Button from 'react-bootstrap/Button';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as OrderActions from 'bv360/redux/actions/orders';
import { SmsSchema, StandaloneSmsSchema } from './validationSchema';

import TextField from 'shared/components/form/fields/text';

export default ({
  doc_type,
  error,
  initiatorEmail,
  payer,
  loading,
  qsValues,
  setQsValues,
  standalone = false
}) => {
  const dispatch = useDispatch();

  return (
    <Formik
      displayName={'VerifySMSCode'}
      onSubmit={vals => {
        setQsValues({
          ...qsValues,
          initiatorEmail: initiatorEmail,
          payer: payer
        });

        dispatch(
          OrderActions.verifySmsQuickSign(
            {
              ...vals,
              doc_type
            },
            standalone
          )
        );
      }}
      initialValues={qsValues}
      validationSchema={standalone ? StandaloneSmsSchema : SmsSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ submitForm, errors, isValid, values }) => (
        <form>
          {!qsValues.verifyBySms && (
            <Row className="form-group">
              <Col md={3}>
                <p>
                  <em>Email sent to {initiatorEmail}</em>
                </p>
              </Col>
            </Row>
          )}
          <Row className="form-group">
            <Col md={3}>
              <Field
                label="Verification Code"
                maxLength={6}
                name="code"
                disabled={loading}
                placeholder="6-digit code"
                component={TextField}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col md={10}>
              {error && (
                <span className="has-error">
                  <i className="fa fa-exclamation-triangle fa-fw has-error" />
                  {error}
                </span>
              )}
            </Col>
            <Col md={2}>
              <Button
                type="button"
                disabled={!isValid || loading}
                onClick={submitForm}
                className="float-end"
              >
                {loading ? <i className="fa fa-fw fa-circle-notch fa-spin" /> : 'Submit'}
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

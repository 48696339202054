import React, { PureComponent } from 'react';
import { Field } from 'formik';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import AddressLookupField from 'shared/components/form/fields/address-lookup';
import AutoSuggestField from 'shared/components/form/fields/auto-suggest';
import DatePickerField from 'shared/components/form/fields/date';
import RadioGroupField from 'shared/components/form/fields/radio-group';
import SelectField from 'shared/components/form/fields/select';
import GenericField from 'shared/components/form/fields/generic-field';
import PhoneField from 'shared/components/form/fields/phone';
import Spinner from 'shared/components/spinner/spinner';
import moment from 'moment';
import { showToast } from 'shared/utils/toast';
import { normalizeOrderDates } from 'exogen/redux/actions/orders';

import { DocumentTypes, InternalDocusignStatus } from 'shared/utils/constants';
import apiClient from 'bv360/utils/api-client';

import AttestationModal from './attestation-modal';
import AttestationEsignModal from './attestation-esign-modal';
import ErrorModal from './error-modal';
import ThanksModal from './thanks-modal';
import WarnModal from './warn-modal';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const VALID_DOC_STATUS = [InternalDocusignStatus.Created, InternalDocusignStatus.InProgress];

export const formatDate = val =>
  val
    ? moment
        .utc(val)
        .format('YYYY-MM-DD')
        .toString()
    : null;

const genderOptions = [
  {
    label: 'Male',
    value: 'male',
    name: 'patient.gender'
  },
  {
    label: 'Female',
    value: 'female',
    name: 'patient.gender'
  }
];

const radioGraphicOptions = [
  {
    label: 'Yes',
    value: 'y',
    name: 'radioGraphicEvidence'
  },
  {
    label: 'No',
    value: 'n',
    name: 'radioGraphicEvidence'
  }
  // {
  //   label: 'Does not apply',
  //   value: 'd',
  //   name: 'radioGraphicEvidence'
  // }
];

const openSurgicalIntervention = [
  {
    label: 'Yes',
    value: 'y',
    name: 'openSurgicalIntervention'
  },
  {
    label: 'No',
    value: 'n',
    name: 'openSurgicalIntervention'
  }
  // {
  //   label: 'Does not apply',
  //   value: 'd',
  //   name: 'openSurgicalIntervention'
  // }
];

function saveData(blob, fileName) {
  // does the same as FileSaver.js
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.style.cssText = 'display: none';

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}

const renderSuggestion = suggestion => suggestion.label;

export default class OrderForm extends PureComponent {
  state = {
    today: new Date(),
    downloading: false,
    submitting: false,
    showAttestation: false,
    showEsignAttestation: false,
    showErrorModal: false,
    showThanksModal: false,
    showWarnModal: false
  };

  componentDidMount() {
    const { isValid, validateForm } = this.props;

    // if the physician information was manually inserted

    if (!isValid) {
      validateForm();
    }
  }

  hasInProgressDocument = () => {
    if (!this.props.formValues || !this.props.formValues.docusign_documents) return true;

    return this.props.formValues.docusign_documents.find(
      doc =>
        VALID_DOC_STATUS.includes(doc.internal_status) &&
        doc.document_type.document_name === DocumentTypes.CMN
    );
  };

  getSuggestions = (icd10s, value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength < 2
      ? []
      : icd10s.filter(icd10 => icd10.label.toLowerCase().includes(inputValue));
  };

  getSuggestionValue = suggestion => {
    return suggestion.value;
  };

  getFormData = () => {
    const data = new FormData();

    const { formValues, payer } = this.props;
    const {
      patient,
      physician_if_other_first_name,
      physician_if_other_last_name,
      physician_if_other_npi,
      initiator
    } = formValues;
    data.append('order_id', this.props.currentOrderId);

    data.append(
      'name_answering_section_b',
      `${initiator.first_name} ${initiator.last_name}`.toUpperCase()
    );
    data.append('title_answering_section_b', initiator.title.toUpperCase());
    data.append('patient_name', `${patient.first_name} ${patient.last_name}`.toUpperCase());
    data.append('primary_insurance_policy_num', formValues.primary_insurance_policy_num);
    data.append('second_insurance_policy_num', formValues.second_insurance_policy_num);
    data.append('payer', payer);
    data.append('patient_date_of_birth', patient.date_of_birth);
    data.append(
      'patient_address1',
      `${patient.home_address} ${patient.apt_suite || ''}`.toUpperCase()
    );
    data.append(
      'patient_address2',
      `${patient.city}, ${patient.state} ${patient.zip}`.toUpperCase()
    );
    data.append('patient_sex', patient.gender === 'male' ? 'M' : 'F');
    data.append('patient_phone', patient.primary_telephone);
    data.append('patient_height', patient.height ? patient.height : '');
    data.append('patient_weight', patient.weight ? patient.weight : '');
    data.append(
      'physician_name',
      `${physician_if_other_first_name} ${physician_if_other_last_name}`.toUpperCase()
    );
    data.append('physician_npi', physician_if_other_npi);

    data.append('length_of_need_mos', formValues.lengthOfNeed);
    data.append('diagnosis_code_1', formValues.diagnosis.icd10_0);
    data.append('diagnosis_code_2', formValues.diagnosis.icd10_1);
    data.append('diagnosis_code_3', formValues.diagnosis.icd10_2);
    data.append('diagnosis_code_4', formValues.diagnosis.icd10_3);

    if (this.props.quickSign) {
      data.append('officeZip', formValues.officeZip);
      data.append('patientDOB', formatDate(formValues.patientDOB));
      data.append('patientLastName', formValues.patientLastName);
      data.append('userEmail', formValues.userEmail);
      data.append('uuid', formValues.uuid);
      data.append('phone', formValues.phone);
    } else if (this.props.quickSignStandalone) {
      data.append('officeZip', formValues.officeZip);
      data.append('patientDOB', formatDate(formValues.patientDOB));
      data.append('userEmail', formValues.initiator.email);
      data.append('phone', formValues.phone);
    }

    if (formValues.radioGraphicEvidence === 'y') data.append('yes_6_xray_90', 'Yes');
    if (formValues.radioGraphicEvidence === 'n') data.append('no_6_xray_90', 'Yes');
    if (formValues.radioGraphicEvidence === 'd') data.append('d_6_xray_90', 'Yes');

    if (formValues.openSurgicalIntervention === 'y')
      data.append('yes_12_surgical_intervention', 'Yes');
    if (formValues.openSurgicalIntervention === 'n')
      data.append('no_12_surgical_intervention', 'Yes');
    if (formValues.openSurgicalIntervention === 'd')
      data.append('d_12_surgical_intervention', 'Yes');
    return data;
  };

  getApiUrl = () => {
    if (this.props.quickSignStandalone)
      return '/exogen-rx/api/documents/quick-sign-standalone/download';

    const { authenticated, formValues } = this.props;

    return this.props.quickSign
      ? `/exogen-rx/api/documents/quick-sign/${authenticated ? formValues.uuid + '/' : ''}download`
      : `/exogen-rx/api/documents/cmn`;
  };

  handleDownload = () => {
    console.log('download handling', this.props);
    if (this.props.isValid) {
      this.setState({ showAttestation: true });
    } else {
      this.setState({ showErrorModal: true });
    }
  };

  handleEsign = () => {
    if (this.props.isValid) {
      if (this.hasInProgressDocument()) {
        this.setState({ showWarnModal: true });
      } else {
        this.setState({ showEsignAttestation: true });
      }
    } else {
      this.setState({ showErrorModal: true });
    }
  };

  downloadDocument = (evt, id) => {
    this.setState({ showAttestation: false, downloading: true });
    evt.preventDefault();
    evt.stopPropagation();
    const { formValues } = this.props;

    const url = this.getApiUrl();
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.responseType = 'blob';

    const { patient } = formValues;
    const { goToDownloadSuccess } = this.props;

    xhr.onload = function() {
      saveData(this.response, `${patient.first_name}-${patient.last_name}-cmn.pdf`); // saveAs is a part of FileSaver.js
      showToast({ type: 'success', message: 'CMN Downloaded' });
      goToDownloadSuccess();
    };

    xhr.onerror = function() {
      showToast({ type: 'error', message: 'Error generating pdf' });
      this.setState({ downloading: false });
    };

    const data = this.getFormData();
    xhr.send(data);
    console.log('accepting download', data);
  };

  submitDocusign = async () => {
    this.setState({ submitting: true, showEsignAttestation: false });

    const { formValues, payer } = this.props;



    try {
      const url = this.props.authenticated
        ? 'exogen-rx/api/docusign/cmn'
        : 'exogen-rx/api/docusign/cmn/noauth';
      const result = await apiClient.post(url, {
        params: normalizeOrderDates({ ...formValues, payer, orderId: formValues.id })
      });
      showToast({ type: 'success', message: 'CMN e-Sign Initiated' });
      this.setState({ showThanksModal: true });
    } catch (response) {
      this.setState({ submitting: false });
      const error =
        response.body && response.body.error
          ? JSON.stringify(response.body.error)
          : 'Error creating e-Signing';
      alert(error);
    }
  };

  handleThanks = () => {
    this.props.goToEsignSuccess();
    this.setState({ showEsignAttestation: false });
  };

  render() {
    const { formValues = {}, errors, handleSubmit, icd10s, states, setFieldValue } = this.props;



    if (!formValues) {
      return <Spinner />;
    }

    const { submitting, downloading, showErrorModal, showWarnModal, showThanksModal } = this.state;
    const readOnly = submitting || downloading;
    const { initiator, initiatorEmail } = formValues;
    const getPhysician = values => {
      if (values?.physician) return values.physician;
      return {
        first_name: values?.physician_if_other_first_name,
        last_name: values?.physician_if_other_last_name,
        email: '',
        npi_number: values?.physician_if_other_npi
      };
    };
    const physician = getPhysician(formValues);

    const attestor = initiator
      ? `${this.props.formValues.initiator.first_name} ${this.props.formValues.initiator.last_name}`
      : '';
    return (
      <>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Card className="card-custom">
            <Card.Header>
              <i className="fa fa-file-alt" /> Certificate of Medical Necessity
            </Card.Header>
            <Card.Body>
              <h3>Initiator</h3>
              <Row>
                <Col md={4} className="form-group">
                  <Field
                    name="initiator.first_name"
                    type="text"
                    maxLength={48}
                    validateUntouched
                    component={GenericField}
                    label="First Name*"
                    disabled={readOnly}
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="initiator.last_name"
                    type="text"
                    maxLength={48}
                    validateUntouched
                    component={GenericField}
                    label="Last Name*"
                    disabled={readOnly}
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="initiator.title"
                    type="text"
                    maxLength={48}
                    validateUntouched
                    component={GenericField}
                    label="Title*"
                    disabled={readOnly}
                  />
                </Col>
              </Row>
              <hr />
              <h3>Patient</h3>
              <Row>
                <Col md={4} className="form-group">
                  <Field
                    name="patient.first_name"
                    type="text"
                    maxLength={48}
                    component={GenericField}
                    label="First Name*"
                    disabled={readOnly}
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="patient.last_name"
                    type="text"
                    maxLength={48}
                    component={GenericField}
                    label="Last Name*"
                    disabled={readOnly}
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="patient.date_of_birth"
                    type="date"
                    component={DatePickerField}
                    maxDate={this.state.today}
                    placeholder="m/dd/yyyy"
                    label="Date of Birth*"
                    disabled={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8} className="form-group">
                  <Field
                    name="patient.home_address"
                    aptField="patient.apt_suite"
                    cityField="patient.city"
                    stateField="patient.state"
                    zipField="patient.zip"
                    type="text"
                    component={AddressLookupField}
                    label="Address*"
                    disabled={readOnly}
                    maxLength={48}
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="patient.apt_suite"
                    type="text"
                    component={GenericField}
                    label="Apt/Suite"
                    disabled={readOnly}
                    maxLength={48}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4} className="form-group">
                  <Field
                    name="patient.city"
                    type="text"
                    component={GenericField}
                    label="City*"
                    disabled={readOnly}
                    maxLength={48}
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="patient.state"
                    component={SelectField}
                    simpleValue
                    value={null}
                    options={states}
                    label="State*"
                    disabled={readOnly}
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="patient.zip"
                    type="text"
                    maxLength={5}
                    component={GenericField}
                    label="Zip*"
                    disabled={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4} className="form-group">
                  <Field
                    name="patient.primary_telephone"
                    type="text"
                    component={PhoneField}
                    label="Best Contact Phone*"
                    disabled={readOnly}
                    placeholder="(555) 555-5555"
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="primary_insurance_policy_num"
                    type="text"
                    maxLength={11}
                    component={GenericField}
                    label="Medicare ID/Policy ID*"
                    disabled={readOnly}
                    placeholder="Medicare ID/Policy ID"
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="patient.gender"
                    label="Gender"
                    type="radio"
                    disabled={readOnly}
                    component={RadioGroupField}
                    elems={genderOptions}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={2} className="form-group">
                  <Field
                    name="patient.height"
                    type="text"
                    maxLength={2}
                    component={GenericField}
                    label="Height (inches)"
                    disabled={readOnly}
                    placeholder="(in inches)"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <Field
                    name="patient.weight"
                    maxLength={3}
                    type="text"
                    component={GenericField}
                    label="Weight (lbs)"
                    disabled={readOnly}
                    placeholder="(in pounds)"
                  />
                </Col>
              </Row>
              <hr />
              <h3>Physician</h3>
              <Row>
                <Col md={4} className="form-group">
                  <Field
                    name="physician_if_other_first_name"
                    type="text"
                    maxLength={48}
                    component={GenericField}
                    label="First Name*"
                    validateUntouched
                    disabled={readOnly}
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="physician_if_other_last_name"
                    type="text"
                    maxLength={48}
                    component={GenericField}
                    label="Last Name*"
                    validateUntouched
                    disabled={readOnly}
                  />
                </Col>
                <Col md={4} className="form-group">
                  <Field
                    name="physician_if_other_npi"
                    type="text"
                    component={GenericField}
                    label="NPI*"
                    disabled={readOnly}
                    maxLength={10}
                    validateUntouched
                    placeholder="NPI"
                  />
                </Col>
              </Row>

              <hr />
              <h3>Diagnosis</h3>
              <Row>
                <Col md={4} className="form-group">
                  <Field
                    name="lengthOfNeed"
                    type="text"
                    maxLength={2}
                    validateUntouched
                    component={GenericField}
                    label="Estimated Length of Need*"
                    disabled={readOnly}
                    placeholder="1-99 (# of months)"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3} className="form-group">
                  <Field
                    name="diagnosis.icd10_0"
                    component={SelectField}
                    simpleValue
                    value={null}
                    options={icd10s}
                    label="Diagnosis Code*"
                    optionHeight={val => {
                      if (val?.option?.label?.length > 90) {
                        return 140;
                      } else {
                        return 80;
                      }
                    }}
                    validateUntouched
                    disabled={readOnly}
                  />
                </Col>
                <Col md={3} className="form-group">
                  <Field
                    name="diagnosis.icd10_1"
                    component={SelectField}
                    simpleValue
                    value={null}
                    options={icd10s}
                    label="Diagnosis Code"
                    optionHeight={val => {
                      if (val?.option?.label?.length > 90) {
                        return 140;
                      } else {
                        return 80;
                      }
                    }}
                    // optionHeight={140}
                    disabled={readOnly}
                  />
                </Col>

                <Col md={3} className="form-group">
                  <Field
                    name="diagnosis.icd10_2"
                    component={SelectField}
                    simpleValue
                    value={null}
                    options={icd10s}
                    label="Diagnosis Code"
                    optionHeight={val => {
                      if (val?.option?.label?.length > 90) {
                        return 140;
                      } else {
                        return 80;
                      }
                    }}
                    disabled={readOnly}
                  />
                </Col>
                <Col md={3} className="form-group">
                  <Field
                    name="diagnosis.icd10_3"
                    component={SelectField}
                    simpleValue
                    value={null}
                    options={icd10s}
                    label="Diagnosis Code"
                    optionHeight={val => {
                      if (val?.option?.label?.length > 90) {
                        return 140;
                      } else {
                        return 80;
                      }
                    }}
                    disabled={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="form-group">
                  <Field
                    name="radioGraphicEvidence"
                    validateUntouched
                    component={RadioGroupField}
                    elems={radioGraphicOptions}
                    label="In a fracture, has there been no clinically significant radiographic evidence of healing for a minimum of 90 days?*"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="form-group">
                  <Field
                    name="openSurgicalIntervention"
                    validateUntouched
                    component={RadioGroupField}
                    elems={openSurgicalIntervention}
                    label="Has there been at least one open surgical intervention for treatment of the fracture?*"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="form-group" md={{ span: 5, offset: 7 }}>
                  <Button onClick={this.handleDownload} disabled={readOnly} className="btn--fw">
                    <i className="fa fa-download" />{' '}
                    {downloading ? (
                      <i className="fa fa-fw fa-circle-notch fa-spin" />
                    ) : (
                      'Download to Print and Sign'
                    )}
                  </Button>{' '}
                  <Button onClick={this.handleEsign} className="btn--fw" disabled={readOnly}>
                    <i className="fa fa-pen-square" />{' '}
                    {submitting ? (
                      <i className="fa fa-fw fa-circle-notch fa-spin" />
                    ) : (
                      'eSign via DocuSign'
                    )}
                  </Button>
                </Col>
              </Row>
              <AttestationModal
                attestor={attestor}
                decline={() => this.setState({ showAttestation: false })}
                show={this.state.showAttestation}
                handleAccept={this.downloadDocument}
              />
              {showErrorModal && (
                <ErrorModal
                  show={showErrorModal}
                  close={() => this.setState({ showErrorModal: false })}
                  errors={errors}
                />
              )}

              {showWarnModal && (
                <WarnModal
                  accept={() => this.setState({ showEsignAttestation: true, showWarnModal: false })}
                  show={showWarnModal}
                  close={() => this.setState({ showWarnModal: false })}
                />
              )}

              {showThanksModal && (
                <ThanksModal
                  accept={this.handleThanks}
                  show={showThanksModal}
                  close={this.handleThanks}
                />
              )}
            </Card.Body>
          </Card>
        </form>
        <AttestationEsignModal
          attestor={attestor}
          initiatorEmail={initiatorEmail}
          decline={() => this.setState({ showEsignAttestation: false })}
          show={this.state.showEsignAttestation}
          handleAccept={this.submitDocusign}
          setFieldValue={setFieldValue}
          physician={physician}
        />
      </>
    );
  }
}

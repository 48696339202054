import moment from 'moment';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { States } from 'shared/utils/constants';
import { physicianNameRx } from 'shared/utils/formatting';

import {
  DATA_LAST_SYNCED,
  DOCUMENT_TYPES_ALL,
  DOCUMENT_TYPES_ALL_FAIL,
  DOCUMENT_TYPES_ALL_SUCCESS,
  ICD10S_ALL_SUCCESS,
  ORDER_TYPES_ALL_SUCCESS,
  PHYSICIANS_ALL,
  PHYSICIANS_ALL_FAIL,
  PHYSICIANS_ALL_SUCCESS,
  SET_PHYSICIANS
} from 'bv360/redux/actions/form-data';

import { USER_NEW } from 'bv360/redux/actions/user';

const sortByDisplay = (key = 'display_name') => {
  return (a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };
};

const renderableArray = (
  elems,
  { value = 'id', label = 'display_name', sortByLabel = true } = {}
) => {
  const sortFunc = sortByDisplay(label);
  const sortedElems = sortByLabel ? elems.sort(sortFunc) : elems;

  return sortedElems.map(elem => {
    return {
      ...elem,
      value: elem[value],
      label: elem[label]
    };
  });
};

const sortByLabel = sortByDisplay('label');
const sortByLastName = sortByDisplay('lname');

const formattedIcd10s = items => {
  return items
    ? items.map(({ code, description }) => ({ label: `${code} ${description}`, value: code }))
    : [];
};

const formattedOffices = items => {
  try {
    return items
      ? items.reduce((acc, practice) => {
          practice.offices.forEach(office => {
            acc.push({
              ...office,
              label: office.name
            });
          });
          return acc;
        }, [])
      : [];
  } catch (e) {
    console.error('Error formatting offices', e);
  }
};

const formattedPhysicians = items => {
  try {
    return items
      ? items.map(mapping => ({
          practice_id: mapping.id,
          items: mapping.physicians.map(phys => ({
            ...phys,
            label: `Dr. ${phys.first_name} ${phys.last_name}`
          }))
        }))
      : [];
  } catch (e) {
    console.error('Error formatting physicians', e);
  }
};

const initialState = {
  states: renderableArray(States, { label: 'name' }),
  documentTypes: [],
  icd10s: [],
  physicians: {
    loading: false,
    physicians: [],
    practices: []
  },
  orderTypes: [],
  lastSynced: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REHYDRATE:
      return action.payload && action.payload.formData
        ? {
            ...state,
            ...action.payload.formData,
            states: renderableArray(States, { label: 'name' })
          }
        : state;
    case DATA_LAST_SYNCED:
      return {
        ...state,
        lastSynced: moment.utc().format()
      };
    case DOCUMENT_TYPES_ALL_SUCCESS:
      return {
        ...state,
        documentTypes: renderableArray(action.result.data)
      };
    case ICD10S_ALL_SUCCESS:
      return {
        ...state,
        icd10s: formattedIcd10s(action.result.data)
      };
    case ORDER_TYPES_ALL_SUCCESS:
      return {
        ...state,
        orderTypes: renderableArray(action.result.data)
      };
    case PHYSICIANS_ALL:
      return {
        ...state,
        practiceMap: {
          ...state.practiceMap,
          loading: true
        }
      };
    case PHYSICIANS_ALL_SUCCESS:
      const offices = formattedOffices(action.result.data);
      const physicians = formattedPhysicians(action.result.data);

      return {
        ...state,
        practiceMap: {
          loading: false,
          physicians,
          offices
        }
      };
    case PHYSICIANS_ALL_FAIL:
      return {
        ...state,
        physpracticeMapicians: {
          ...state.practiceMap,
          loading: false
        }
      };
    // For now, let it ride...
    case DOCUMENT_TYPES_ALL:
    case DOCUMENT_TYPES_ALL_FAIL:
    case SET_PHYSICIANS:
      return {
        ...state,
        practiceMap: {
          loading: false,
          physicians: formattedPhysicians(action.physicians),
          offices: formattedOffices(action.physicians)
        }
      };
    case USER_NEW:
      return {
        ...state,
        practiceMap: {
          ...state.practiceMap,
          physicians: [],
          offices: []
        }
      };
    default:
      return state;
  }
}

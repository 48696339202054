import React from 'react';
import { format } from 'libphonenumber-js';

const getOfficeVals = office => {
  if (!office) return {};
  const { name, office_name, address1, city, state, zip, main_telephone, current_fax } = office;

  let cityStateZip = '';
  if (city && state) cityStateZip += `${city}, ${state}`;
  if (!city && state) cityStateZip += `${state}`;
  if (state && zip) cityStateZip += ` ${zip}`;

  return {
    name,
    office_name,
    address1,
    cityStateZip,
    main_telephone,
    current_fax
  };
};

export default ({ onChange, office, readOnly }) => {
  if (!office) return null;

  const { practice_name, name, practice_npi } = office;

  const { address1, cityStateZip, main_telephone, current_fax } = getOfficeVals(office);

  const clear = evt => {
    evt.preventDefault();
    onChange(null);
  };

  return (
    <div>
      <div>
        {readOnly ? (
          <strong>{practice_name}</strong>
        ) : (
          <a href="#" onClick={clear}>
            <strong>{practice_name || name}</strong>
            <i className="fa fa-times-circle fa-fw fa-lg" />
          </a>
        )}
      </div>

      {address1 && <div>{address1}</div>}
      <div>{cityStateZip}</div>
      {main_telephone && (
        <div>
          <em>Tel: {format(main_telephone, 'US', 'National')}</em>
        </div>
      )}
      {current_fax && (
        <div>
          <em>Fax: {format(current_fax, 'US', 'National')}</em>
        </div>
      )}

      {practice_npi && <div>NPI: {practice_npi}</div>}
    </div>
  );
};

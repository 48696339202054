import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

class ThanksModal extends Component {
  render() {
    const { close, show } = this.props;

    return (
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-check-circle-o fa-success" /> eSign Created
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Thank you for initiating an eSign CMN.</p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-default btn--fw" onClick={close}>
            <span>
              <i className="fa fa-times fa-fw" />
              Close
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ThanksModal.propTypes = {
  close: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default ThanksModal;

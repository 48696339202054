import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from 'bv360/redux/actions/user';
import apiClient from 'bv360/utils/api-client';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import superagent from 'superagent';
import { URLS } from 'bv360/utils/urls';

export default function(props) {
    return (
      <Modal
        show = {props.show}
        onHide = {props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:12}} >
            <h3>Terms of Use</h3> 
            PLEASE READ THIS WEBSITE PRIVACY POLICY CAREFULLY BEFORE USING THE WEBSITE.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <strong>Agreement to the Terms of Use as a Prerequisite to Using the Website<br /></strong>
        <p>Welcome to the Bioventus LLC. (hereinafter “Bioventus”) website <a href="https://www.exogen.com/">www.exogen.com </a>(the “Website”). 
        By accessing and using the Website, you agree to be bound by the following 
        Terms &amp; Conditions of Use, including 
        without limitation the disclaimer of all warranties, both express and implied, the disclaimer of all liability, 
        and the Privacy Policy. If you do not agree to 
        the Terms &amp; Conditions of Use, you are not permitted to use 
        the Website.</p>
        
        <strong>Scope<br /></strong>
        <p>The Terms &amp; Conditions of 
        Use set forth in this agreement apply only to your use of this Website. Bioventus operates 
        other websites that are covered by their own terms and conditions of use as specified within those websites.</p>

        <strong>Health Information<br /></strong>
        <p>The information on this Website is provided for informational purposes only. The information on this 
        Website is intended only for the purpose of helping patients and family members 
        better understand certain health conditions and treatment options. The content is not intended in any 
        way to be a substitute for professional medical advice and should not be interpreted as 
        treatment recommendations. Only a physician who has had an opportunity to interact with the patient in 
        person, with access to the patient’s records and the opportunity to conduct appropriate follow up, can 
        provide recommendations for treatment. Always seek the advice of your physician or other qualified health care 
        provider with any questions you have regarding a medical condition. The Website content is not intended 
        to be relied on for medical diagnosis or treatment. Never disregard medical advice or delay in seeking it 
        because of something you read on this Website.</p>

        <strong>Right to Modify the Terms &amp; Conditions of Use<br /></strong>
        <p>Bioventus reserves the right to modify these Terms &amp; 
        Conditions of Use at any time, in its sole discretion, with or without notice. All modifications 
        to these Terms &amp; Conditions of Use will be 
        effective immediately upon posting of the modifications on the Website. You agree to check this webpage each time you 
        access the Website to become aware of any changes. By continuing to access the Website, you agree to be bound by 
        these modifications to the Terms &amp; Conditions of Use.</p>

        <strong>Applicability to Online Activities Only<br /></strong>
        <p>You acknowledge and agree that, unless Bioventus specifically provides otherwise, 
        these Terms &amp; Conditions of Use only apply 
        to this Website and Bioventus’ online activities through the Website, and do not apply to any of Bioventus’ 
        offline activities.</p>

        <strong>User Eligibility<br /></strong>
        <p>The Website and its services are available only to individuals and entities who can form legally binding 
        contracts under applicable law. Without limiting the foregoing, the Website and its services are not available 
        to anyone under the age of thirteen (13) years. If you do not meet the qualifications set forth in this paragraph, 
        you are not permitted to use the Website.</p>
        <p>Bioventus makes no representation that the materials on the Website are appropriate or 
        available for use in other countries. Additionally, product indications for use may vary by country.</p>

        <strong>Right to Terminate Access<br /></strong>
        <p>Bioventus, in its sole discretion and with or without cause, may terminate your access to and use of 
        the Website. Bioventus may exercise its rights pursuant to this paragraph at any time without prior notice 
        and without liability to you or to any third party.</p>

        <strong>Copyright Notice<br /></strong>
        <p>All content included on the Website, including without limitation all text, graphics, logos, 
        images, photographs, videos, music and sounds, is the property of Bioventus (© 2019 Bioventus LLC. 
        All rights reserved.) or is used with permission and is protected to the full extent of US and international 
        copyright laws. The Website and its content may only be used as permitted by Bioventus on the 
        Website. You may download content only for your personal use for non-commercial purposes. All other 
        reproductions and uses, including reproduction for commercial purposes or non-personal use, modification, 
        distribution, transmission, republication, display or performance of the content, are strictly prohibited 
        without the express, prior, written permission of Bioventus. You may not frame or link to the Website without 
        Bioventus’ express, prior, written permission. Violations of Bioventus’ copyrights may result in severe 
        civil and criminal penalties.</p>

        <strong>Trademark Notice<br /></strong>
        <p>Trademarks appearing on the Website, including without limitation BIOVENTUS and EXOGEN, are the 
        property of their respective owners and may not be reproduced without the express written permission of their 
        owners. The design and layout of the Website is protected as trade dress and may not be copied or imitated in 
        whole or in part. Violations of Bioventus’ trademark rights may result in severe civil and criminal penalties.</p>

        <strong>Information You Provide to Bioventus<br /></strong>
        <p>If you submit any ideas, suggestions, notes, drawings, graphics, concepts, or other information (collectively “Ideas”) 
        to Bioventus, you are giving those Ideas, and all your rights in them, to Bioventus free of charge. 
        Such Ideas shall be deemed to be non-confidential and Bioventus shall have no obligation of any kind with 
        respect to such Ideas and shall be free to reproduce, use, disclose and distribute the Ideas to others without 
        limitation, without your consent or any compensation to you or anyone else. Bioventus shall be free to use any 
        know-how or techniques contained in such Ideas for any purpose whatsoever including but not limited to developing, 
        manufacturing and marketing products incorporating such information.</p>

        <strong>Disclaimer of Warranties; Limitation of Liability<br /></strong>
        <p>By accessing this Website, you agree that the use of the Website is at your sole risk. The Website may include 
        technical inaccuracies or typographical errors. Bioventus does not warrant the accuracy of any of the information or 
        representations appearing on the Website.</p>
        <p>THIS WESBITE IS PROVIDED BY BIOVENTUS ON AN “AS IS” AND “AS AVAILABLE” BASIS. BIOVENTUS MAKES NO 
        REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATON OF THE WEBSITE, THE INFORMATION, 
        CONTENT, MATERIALS OR PRODUCTS INCLUDED ON THIS SITE. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, BIOVENTUS 
        DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, 
        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>
        <p>BIOVENTUS WILL NOT BE LIABILE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE WEBSITE, 
        INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES. NO ORAL 
        ADVICE OR WRITTEN INFORMATION GIVEN BY BIOVENTUS SHALL CREATE A WARRANTY, NOR SHALL YOU RELY ON ANY SUCH 
        INFORMATION OR ADVICE.</p>

        <strong>Disclaimer of Responsibility for Material Posted on “Linked” Sites<br /></strong>
        <p>There may be links on the Website that allow you to leave the Website and to visit other websites. Accordingly, 
        Bioventus is not and cannot be held liable or responsible for the content, services, images, graphics, products, or 
        representations of any linked websites. Bioventus cannot and does not guarantee, represent or warrant that the content 
        contained in those linked websites is accurate, legal, or inoffensive, or that those websites do not contain viruses 
        or will not otherwise adversely affect your computer. Bioventus provides links to these other websites solely as a 
        convenience to you. Bioventus' inclusion of any link does not imply that Bioventus sponsors, endorses, is affiliated 
        with or approves of the linked website.</p>

        <strong>Indemnification<br /></strong>
        <p>As a condition of your use of the Website, you represent and warrant to Bioventus that you will not use the 
        Website for unlawful purposes or in ways which are prohibited by these Terms &amp; Conditions of Use or which could 
        damage or disable the Website or interfere with any other party’s use and enjoyment of the Website. You agree to 
        indemnify and hold Bioventus, its officers, agents, and employees harmless from any loss, liability, damage, cost, or 
        expense, including reasonable attorneys fees, resulting from any actions, suits, or claims made by any third 
        party arising out of or relating to your use of the Website.</p>

        <strong>Privacy Policy<br /></strong>
        <p>In accordance with the terms of the Website Privacy Policy, Bioventus respects the privacy of its Users. To view the 
        Website Privacy Policy, <a href="https://www.exogen.com/us/physician/privacy-policy/">click here.</a></p>

        <strong>Applicable Law<br /></strong>
        <p>Bioventus has offices in Durham, North Carolina. Any dispute arising from or relating to 
        these Terms &amp; Conditions of Use shall be governed by the laws of the state of North Carolina, without giving 
        effect to its conflict of law principles. You hereby irrevocably and unconditionally consent to submit to the exclusive 
        jurisdiction of the state and federal courts located in Durham County, North Carolina for any litigation arising 
        out of or relating to these Terms &amp; Conditions of Use.</p>

        <strong>Severability<br /></strong>
        <p>The provisions of these Terms &amp; Conditions of Use are severable and the invalidity or unenforceability of one 
        provision shall not affect the validity and enforceability of the other provisions.</p>

        <strong>Merger<br /></strong>
        <p>These Terms &amp; Conditions of Use, along with 
        the Privacy Policy and any other policies referred 
        to herein which are hereby incorporated by reference, represent the entire agreement between the parties as to the subject 
        matter of this agreement and supersede any prior representations or agreements, whether written or oral, between the parties.</p>

        <strong>reCAPTCHA<br /></strong>
        <p>This site is protected by reCAPTCHA and the Google {' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and {' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
        <strong>Revision Date<br /></strong>
        <p>These Terms &amp; Conditions of Usewere last revised on September 17, 2012.</p>
  
        </Modal.Body>
        <Modal.Footer/>

      </Modal>
    );
  }
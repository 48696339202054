import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import CmnForm from './form';
import { normalizeOrderDatesForForm } from 'exogen/redux/actions/orders';
import Spinner from 'shared/components/spinner/spinner';
import { URLS } from 'bv360/utils/urls';
import * as RxUrls from 'exogen-rx/utils/urls';
import * as DocumentActions from 'bv360/redux/reducers/documents';
import * as OrderActions from 'bv360/redux/actions/orders';
import validationSchema from './validationSchemaQs';

class QuickSignCmn extends PureComponent {
  componentWillUnmount() {
    this.props.actions.clearCurrentOrder();
  }

  render() {
    if (!this.props.currentOrder) return <Spinner />;

    const { currentOrderId, qsValues, actions, isRx } = this.props;

    const { goToEsignSuccess, goToRxSuccess } = actions;
    return (
      <Formik
        initialValues={{
          ...normalizeOrderDatesForForm(this.props.currentOrder),
          ...this.props.qsValues,
          physician_if_other_first_name: this.props.qsValues.physician_if_other_first_name || '',
          physician_if_other_last_name: this.props.qsValues.physician_if_other_last_name || '',
          physician_if_other_npi: this.props.qsValues.physician_if_other_npi || '',
          diagnosis: {
            icd10_0: '',
            icd10_1: '',
            icd10_2: '',
            icd10_3: ''
          },
          radioGraphicEvidence: '',
          openSurgicalIntervention: ''
        }}
        onSubmit={this.props.actions.submitQsCmn}
        validationSchema={validationSchema}
        displayName={'CmnForm'}
        isInitialValid={false}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
        render={({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          status,
          isSubmitting,
          isValid,
          submitForm,
          validateForm
        }) => (
          <CmnForm
            // authenticated={!!this.props.user}
            currentOrderId={currentOrderId}
            formValues={values}
            handleSubmit={handleSubmit}
            icd10s={this.props.icd10s}
            states={this.props.states}
            errors={errors}
            quickSign
            payer={qsValues?.payer}
            authenticated={this.props.authenticated}
            uuid={this.props.uuid}
            isSubmitting={isSubmitting}
            isValid={isValid}
            goToOrder={() => {
              this.props.actions.goTo();
            }}
            goToDownloadSuccess={() => {
              if (isRx) {
                goToRxSuccess();
              } else {
                this.props.actions.goToDownloadSuccess();
              }
            }}
            goToEsignSuccess={() => {
              if (isRx) {
                goToRxSuccess();
              } else {
                goToEsignSuccess();
              }
            }}
            setFieldValue={setFieldValue}
            status={status}
            submitForm={submitForm}
            validateForm={validateForm}
          />
        )}
      />
    );
  }
}

QuickSignCmn.propTypes = {
  actions: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  submitFailed: PropTypes.bool
};

QuickSignCmn = connect(
  (state, ownProps) => ({
    currentOrderId: state.orders.currentOrderId,
    currentOrder: state.orders.currentOrder,
    icd10s: state.formData.icd10s,
    states: state.formData.states
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(DocumentActions, dispatch),
      ...bindActionCreators(OrderActions, dispatch),
      goTo: () => window.location.replace(URLS.QUICK_SIGN_SUCCESS),
      goToDownloadSuccess: () => window.location.replace(URLS.QUICK_SIGN_DOWNLOAD_SUCCESS),
      goToEsignSuccess: () => window.location.replace(URLS.QUICK_SIGN_ESIGN_SUCCESS),
      goToRxSuccess: () => window.location.replace(RxUrls.URLS.BASE)
    }
  })
)(QuickSignCmn);

export default QuickSignCmn;

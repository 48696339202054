import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

class AttestationModal extends Component {
  render() {
    const { attestor, decline, handleAccept, show } = this.props;

    return (
      <Modal show={show} onHide={decline}>
        <Modal.Header closeButton>
          <Modal.Title>Attestation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            I, {attestor}, attest that the information I have provided is accurate and verifiable
            based on our medical records.
          </p>
          <p>
            The CMN will download after you accept the Attestation. Please Print the form and have
            the HCP sign and fax to <strong>866-486-2192</strong>.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-default btn--fw" onClick={handleAccept}>
            <span>
              <i className="fa fa-check fa-fw" />
              Accept
            </span>
          </button>
          <button type="button" className="btn btn-default btn--fw" onClick={decline}>
            <span>
              <i className="fa fa-times fa-fw" />
              Decline
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AttestationModal.propTypes = {
  decline: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default AttestationModal;

import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useSelector, useDispatch } from 'react-redux';
import { URLS, urlStitch } from 'exogen-rx/utils/urls';
import Spinner from 'shared/components/spinner/spinner';
import * as FormDataActions from 'bv360/redux/actions/form-data';
import { LOADING } from 'bv360/redux/reducers/orders';
import ErrorBoundary from 'exogen/components/error/error';

import Panel from 'exogen/components/common/pages/panel';
import Header from 'bv360/components/exogen-rx/app/header';
import Footer from 'bv360/components/app/footer';
import FormSelector from './form-selector';

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import VerifyDetailsForm from './verify-details-form';
import VerifyCodeForm from './verify-code-form';

const QuickSign = props => {
  const [qsValues, setQsValues] = useState(null);
  const [stopLoad, setStopLoad] = useState(false);
  const dispatch = useDispatch();

  const {
    currentOrder,
    quickSignDocType,
    initiatorEmail,
    payer,
    smsVerified,
    smsSent,
    error,
    loading,
    smsPending,
    icd10s,
    user
  } = useSelector(state => ({
    currentOrder: state.orders.currentOrder,
    quickSignDocType: state.orders.quickSignDocType,
    initiatorEmail: state.orders.initiatorEmail,
    payer: state.orders.payer,
    smsVerified: state.orders.smsVerified,
    smsSent: state.orders.smsSent,
    error: state.orders.error,
    loading: state.orders.loading.includes(LOADING.SMS_CODE),
    smsPending: state.orders.loading.includes(LOADING.SMS_CODE),
    icd10s: state.icd10s,
    user: state.user.user
  }));

  useEffect(() => {
    if (user) {
      setStopLoad(true);
      window.location.href = urlStitch(URLS.QUICK_SIGN_LOGGED_IN, { uuid: match.params.uuid });
    }
    dispatch(FormDataActions.getICD10s());
  }, []);

  const { googleReCaptchaProps, match } = props;
  const combinedProps = {
    ...props,
    currentOrder,
    quickSignDocType,
    initiatorEmail,
    payer,
    smsVerified,
    smsSent,
    error,
    loading,
    smsPending,
    icd10s,
    user
  };

  const verificationInProcess = !smsVerified || !currentOrder || !quickSignDocType;
  const showActualForm = smsVerified && currentOrder && quickSignDocType;

  if (stopLoad) return <Spinner />;

  return (
    <>
      <Header externalPage={true} />
      <ErrorBoundary>
        <Container className="content--app">
          {verificationInProcess && (
            <Panel title="Sign in or Verify Identity">
              {smsSent ? (
                <VerifyCodeForm
                  error={error}
                  initiatorEmail={initiatorEmail}
                  payer={payer}
                  loading={loading}
                  qsValues={qsValues}
                  setQsValues={setQsValues}
                />
              ) : (
                <VerifyDetailsForm
                  error={error}
                  executeRecaptcha={googleReCaptchaProps.executeRecaptcha}
                  setQsValues={setQsValues}
                  smsPending={smsPending}
                  uuid={match.params.uuid}
                />
              )}
            </Panel>
          )}

          {showActualForm && (
            <Panel title="Quick Sign">
              <FormSelector {...combinedProps} qsValues={qsValues} />
            </Panel>
          )}
        </Container>
      </ErrorBoundary>
      <Footer />
    </>
  );
};

export default withGoogleReCaptcha(QuickSign);

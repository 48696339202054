import moment from 'moment';

export const DATA_LAST_SYNCED = 'DATA_LAST_SYNCED';

export const ORDER_TYPES_ALL = 'ORDER_TYPES_ALL';
export const ORDER_TYPES_ALL_SUCCESS = 'ORDER_TYPES_ALL_SUCCESS';
export const ORDER_TYPES_ALL_FAIL = 'ORDER_TYPES_ALL_FAIL';

export const DOCUMENT_TYPES_ALL = 'DOCUMENT_TYPES_ALL';
export const DOCUMENT_TYPES_ALL_SUCCESS = 'DOCUMENT_TYPES_ALL_SUCCESS';
export const DOCUMENT_TYPES_ALL_FAIL = 'DOCUMENT_TYPES_ALL_FAIL';

export const ICD10S_ALL = 'ICD10S_ALL';
export const ICD10S_ALL_SUCCESS = 'ICD10S_ALL_SUCCESS';
export const ICD10S_ALL_FAIL = 'ICD10S_ALL_FAIL';

export const PHYSICIANS_ALL = 'PHYSICIANS_ALL';
export const PHYSICIANS_ALL_SUCCESS = 'PHYSICIANS_ALL_SUCCESS';
export const PHYSICIANS_ALL_FAIL = 'PHYSICIANS_ALL_FAIL';

export const SET_PHYSICIANS = 'SET_PHYSICIANS';

export function getDocumentTypes() {
  return {
    types: [DOCUMENT_TYPES_ALL, DOCUMENT_TYPES_ALL_SUCCESS, DOCUMENT_TYPES_ALL_FAIL],
    promise: client => client.get('exogen-rx/api/document-types/')
  };
}

export function getICD10s() {
  return {
    types: [ICD10S_ALL, ICD10S_ALL_SUCCESS, ICD10S_ALL_FAIL],
    promise: client => client.get('exogen-rx/api/icd10s/')
  };
}

export function getPhysicians() {
  return {
    types: [PHYSICIANS_ALL, PHYSICIANS_ALL_SUCCESS, PHYSICIANS_ALL_FAIL],
    promise: client => client.get('exogen-rx/api/physicians')
  };
}

export function getOrderTypes() {
  return {
    types: [ORDER_TYPES_ALL, ORDER_TYPES_ALL_SUCCESS, ORDER_TYPES_ALL_FAIL],
    promise: client => client.get(`order-types/`)
  };
}

export function setPhysicians(physicians) {
  return {
    type: SET_PHYSICIANS,
    physicians
  };
}

export function setLastSynced() {
  return {
    type: DATA_LAST_SYNCED
  };
}

export function getApplicationData() {
  return (dispatch, getState) => {
    const lastSyncedRaw = getState().formData.lastSynced;
    const lastSynced = moment(lastSyncedRaw);
    const now = moment.utc();
    const diff = now.diff(lastSynced, 'minutes');
    dispatch(getPhysicians());
    dispatch(getDocumentTypes());

    if (!lastSyncedRaw || diff >= 30) {
      console.log('System data is old...refreshing...');
      dispatch(setLastSynced());
      dispatch(getICD10s());
    }
  };
}

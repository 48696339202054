import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from 'bv360/redux/actions/user';
import apiClient from 'bv360/utils/api-client';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import superagent from 'superagent';
import { URLS } from 'bv360/utils/urls';
import moment from 'moment';
import PrivacyPolicyModal from './modals/privacy-policy';
import TermsOfUseModal from './modals/terms-of-use';

export default function() {
  const [privacyModal, setPrivacyModal] = useState(false);
  const privacyModalToggle = () => setPrivacyModal(!privacyModal);
  const [termsOfUseModal, setTermsOfUseModal] = useState(false);
  const termsOfUseModalToggle = () => setTermsOfUseModal(!termsOfUseModal);

  return (
    <>
      <footer id="Footer">
        <div className="Inner">
          <div className="row">
            <div className="col-lg-8 float-start">
              <ul>
                <li>
                  <a href="#" onClick={privacyModalToggle}>
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" onClick={termsOfUseModalToggle}>
                    Terms of Use
                  </a>
                </li>
              </ul>
            </div>

            {/* <div className="col-lg-4 text-end">
              <div className="ChatBtn">
                <a href="https://lc.chat/now/11308222/" target="_blank">
                  <span className="glyphicon glyphicon-user" aria-hidden="true"></span> Live Chat
                </a>
              </div>
            </div> */}

            <div className="col-sm-12 text--copyright">
              &copy; {moment().year()} Bioventus. All rights reserved.
            </div>
          </div>
        </div>
      </footer>

      <PrivacyPolicyModal show={privacyModal} onHide={privacyModalToggle} />

      <TermsOfUseModal show={termsOfUseModal} onHide={termsOfUseModalToggle} />
    </>
  );
}
